import { all } from 'redux-saga/effects'
import { combineReducers } from '@reduxjs/toolkit'
import game from './ducks/gameDuck'
import joinRoomSaga from './sagas/joinRoomSaga'

export const organiser = combineReducers({ game })

export function* organiserSaga() {
  yield all([joinRoomSaga()])
}
