import styles from './App.module.scss'
import backgroundImage from './shared/assets/music.svg'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Loading } from './shared/ui/Loading/Loading'
import PageViewLogger from './core/ui/reactfire/Tracking/PageViewLogger'
import { SuspenseWithPerf } from 'reactfire'
import Onboarding from './shared/ui/Onboarding/Onboarding'
import { Toaster } from 'react-hot-toast'
import Organiser from './organiser/ui/Organiser'
import Room from './room/ui/Room'

// const Organiser = lazyLoad(() => import('../../organiser/ui/Organiser'));
// const Room = lazyLoad(() => import('../../room/ui/Room'));

export const App = () => {
  return (
    <BrowserRouter>
      <div
        className={styles.app}
        style={{ backgroundImage: `url(${backgroundImage})` }}
        role="main"
      >
        <SuspenseWithPerf fallback={null} traceId={'load-toaster'}>
          <Toaster />
        </SuspenseWithPerf>
        <SuspenseWithPerf fallback={<Loading name="route" />} traceId={'load-root-route'}>
          <Routes>
            <Route path="/" element={<Onboarding />} />
            <Route path="room/:roomCode" element={<Room />} />
            <Route path="organiser/*" element={<Organiser />} />
          </Routes>
        </SuspenseWithPerf>
      </div>
      <SuspenseWithPerf fallback={null} traceId={'load-page-view-logger'}>
        <PageViewLogger />
      </SuspenseWithPerf>
    </BrowserRouter>
  )
}
