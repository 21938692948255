import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Profile } from '../../../user/logic/ducks/profileDuck'
import { playerJoined, playerQuit } from './playersDuck'
import { gameReset } from '../../../game/logic/ducks/lifecycleDuck'

export interface Event {
  timestamp: number
}

export interface Message extends Event {
  message: string
}

export interface ChatMessage extends Message {
  name: string
}

interface ChatState {
  messages: Array<Message | ChatMessage>
}

interface State {
  room: { chat: ChatState }
}

const initialState: ChatState = {
  messages: [],
}

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    sendMessage: (state, action: PayloadAction<Partial<ChatMessage>>) => {},
    readMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.messages.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(gameReset, () => initialState);
    builder.addCase(playerJoined, (state, action: PayloadAction<Partial<Profile>>) => {
      const timestamp = Date.now()
      const { displayName } = action.payload
      state.messages.push({ message: `${displayName} joined.`, timestamp })
    })
    builder.addCase(playerQuit, (state, action: PayloadAction<Partial<Profile>>) => {
      const timestamp = Date.now()
      const { displayName } = action.payload
      state.messages.push({ message: `${displayName} quit.`, timestamp })
    })
  },
})

// Actions

export const { sendMessage, readMessage } = chatSlice.actions

// Reducer

export default chatSlice.reducer

// Selectors

export const selectChatRoot = (state: State) => state.room.chat || initialState
export const selectChatMessages = (state: State) => selectChatRoot(state).messages
