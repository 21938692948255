import { all, delay, put, takeLatest } from 'redux-saga/effects'
import { nextQuestionStarted, questionRevealed } from '../ducks/lifecycleDuck'

function* prepareQuestionForRevealing() {
  yield delay(3000)
  yield put(questionRevealed())
}

export function* questionSaga() {
  yield all([takeLatest(nextQuestionStarted, prepareQuestionForRevealing)])
}
