import { all } from 'redux-saga/effects'
import { combineReducers } from '@reduxjs/toolkit'
import profile from './ducks/profileDuck'
import profileInitialisationSaga from './sagas/profileInitalisation'

export const user = combineReducers({ profile })

export function* userSaga() {
  yield all([profileInitialisationSaga()])
}
