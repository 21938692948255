import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire'
import HashModal from '../../../../core/ui/tailwind/Modal/HashModal'
import { useCallback, useMemo } from 'react'
import { User } from 'firebase/auth'
import Inventory from '../../../model/Inventory'
import { collection, limit, orderBy, query, getDocs } from 'firebase/firestore'
import { useDispatch } from 'react-redux'
import { categorySelected } from '../../../logic/ducks/gameDuck'
import { cloneDeep } from '../../../../core/utils/object'

interface Props {
  index: number
}

function SelectCategoryModal({ index }: Props): JSX.Element {
  const { data: user }: { data: User } = useUser()
  const dispatch = useDispatch()
  const firestore = useFirestore()

  const categoriesCollection = collection(firestore, Inventory.getCategoriesPath(user.uid))
  const categoriesQuery = query(categoriesCollection, orderBy('id', 'asc'))
  const { data: categories } = useFirestoreCollectionData(categoriesQuery, {
    // @ts-ignore
    idField: `id-${user.stsTokenManager.expirationTime}`,
  })

  const categoryAsWord = useMemo(() => {
    if (index === 1) return 'first'
    if (index === 2) return 'second'
    if (index === 3) return 'third'
    if (index === 4) return 'fourth'
    if (index === 5) return 'fifth'
    return ''
  }, [index])

  const onSelectCategory = useCallback(
    async (slug, callback) => {
      const selectedCategory = cloneDeep(categories.find((member) => member.slug === slug))

      // Get questions for that category
      const questionsCollection = collection(
        firestore,
        Inventory.getQuestionsPath(user.uid, selectedCategory.slug),
      )
      const questionsQuery = query(questionsCollection, limit(12))
      const questionSnapshots = await getDocs(questionsQuery)
      const questions = []
      // eslint-disable-next-line unicorn/no-array-for-each
      questionSnapshots.forEach((question) => questions.push(question.data()))

      // typed as sub-collection
      selectedCategory.questions = questions

      dispatch(
        categorySelected({
          index,
          category: selectedCategory,
        }),
      )

      if (callback) callback()
    },
    [categories, firestore, user.uid, dispatch, index],
  )

  return (
    <HashModal
      hash={`#select-category-${index}`}
      title={`Select ${categoryAsWord} category`.replace(/\s+/, ' ')}
      Content={({ closeModal }) => (
        <div>
          <h2>Choose from your list</h2>
          <ul className="text-gray-500 columns-1 sm:columns-2 md:columns-3">
            {categories.map((member) => (
              <li
                key={member.slug}
                onClick={() => onSelectCategory(member.slug, closeModal)}
                className="cursor-pointer"
              >
                {member.title}
              </li>
            ))}
          </ul>
        </div>
      )}
      Footer={({ closeModal }: any) => (
        <>
          <div className="flex-grow" />
          <button
            className="background-transparent uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={closeModal}
          >
            Close
          </button>
        </>
      )}
    />
  )
}

export default SelectCategoryModal
