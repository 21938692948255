import JumboFormPage from 'shared/ui/JumboForm/JumboFormPage'
import Logo from '../Logo/Logo'
import { JoinOrHostStep } from './Steps/JoinOrHostStep'
import Layout from '../Layout/Layout'
import Page from '../Page/Page'

interface Props {}

function Onboarding({}: Props): JSX.Element {
  return (
    <Page>
      <Layout>
        <JumboFormPage>
          <Logo />
          <JoinOrHostStep />
        </JumboFormPage>
      </Layout>
    </Page>
  )
}

export default Onboarding
