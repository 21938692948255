import { Link, Outlet, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { supportedQuizTypes, supportedQuizTypesSlugs } from '../../model/supportedQuizTypes'

interface Props {}

function Inventory({}: Props): JSX.Element {
  const { quizType: currentSlug } = useParams()
  const isSupported = useMemo(() => supportedQuizTypesSlugs.includes(currentSlug), [currentSlug])
  const hasCurrentSlug = Boolean(currentSlug)

  if (currentSlug && !isSupported) {
    return (
      <div className="py-6">
        <h2 className="pb-6">Unsupported game mode</h2>
      </div>
    )
  }

  return (
    <div className="py-6">
      <h2 className="pb-6">Inventory</h2>
      <div className="space-y-4">
        {supportedQuizTypesSlugs.map((slug) => {
          const isActive = slug === currentSlug
          const to = isActive ? '../' : hasCurrentSlug ? `../${slug}` : `${slug}`
          const { title } = supportedQuizTypes[slug]

          return (
            <div
              key={slug}
              className="flex flex-col border-4 border-white rounded-3xl bg-emerald-900 bg-opacity-[96%]"
            >
              <Link to={to} className="p-6">
                {title}
              </Link>

              {isActive && <Outlet />}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Inventory
