import { useSelector } from 'react-redux'
import SelectedCategory from './SelectedCategory'
import {
  selectCategory1,
  selectCategory2,
  selectCategory3,
  selectCategory4,
  selectCategory5,
  selectNumberOfRounds,
} from '../../../logic/ducks/gameDuck'

interface Props {}

function SelectedCategories({}: Props): JSX.Element {
  const rounds = useSelector(selectNumberOfRounds)
  const category1 = useSelector(selectCategory1)
  const category2 = useSelector(selectCategory2)
  const category3 = useSelector(selectCategory3)
  const category4 = useSelector(selectCategory4)
  const category5 = useSelector(selectCategory5)

  return (
    <div className="w-full p-6">
      <div className="w-full border-dashed border-4 rounded-md">
        {rounds >= 1 && <SelectedCategory index={1} {...category1} />}
        {rounds >= 2 && <SelectedCategory index={2} {...category2} />}
        {rounds >= 3 && <SelectedCategory index={3} {...category3} />}
        {rounds >= 4 && <SelectedCategory index={4} {...category4} />}
        {rounds >= 5 && <SelectedCategory index={5} {...category5} />}
      </div>
    </div>
  )
}

export default SelectedCategories
