import { useEffect, useRef } from 'react'
import styles from './Social.module.scss'
import { useDispatch } from 'react-redux'
import { sendMessage } from '../../../logic/ducks/chatDuck'

const ChatInput = ({ message, setMessage }) => {
  const input = useRef<HTMLInputElement>()
  const dispatch = useDispatch()

  const onSubmit = (event) => {
    event.preventDefault()

    dispatch(sendMessage({ message }))

    setMessage('')
  }

  useEffect(() => {
    if (!input.current) return
    input.current.focus()
  }, [input])

  return (
    <form onSubmit={onSubmit} className={styles.chatInput}>
      <input
        ref={input}
        id="chatMessage"
        type="text"
        value={message}
        placeholder="Type a message"
        onChange={(event) => setMessage(event.target.value)}
      />
      {/*<button type="submit">⏎</button>*/}
    </form>
  )
}

export default ChatInput
