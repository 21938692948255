import styles from './Masher.module.scss'
import masherUp from './masher-up.png'
import masherDown from './masher-down.png'
import { useEffect } from 'react'
import useSound from 'use-sound'
import sfxButtonPress from './sfx-button-press.wav'
import sfxButtonReset from './sfx-button-reset.wav'
import { usePrevious } from '../../../core/hooks/usePrevious'
import { useDispatch, useSelector } from 'react-redux'
import { selectHasOwnButtonBeenMashed } from '../../../game/logic/ducks/buttonDuck'
import cx from 'classnames'
import { requestButtonPress } from '../../../game/logic/ducks/lifecycleDuck'
import {
  selectPhotoURL,
  selectUserDisplayName,
  selectUserUid,
} from '../../../user/logic/ducks/profileDuck'

interface Props {
  className?: string
}

const Masher = ({ className }: Props) => {
  const isMashed = useSelector(selectHasOwnButtonBeenMashed)
  const wasMashedBefore = usePrevious(isMashed)
  const uid = useSelector(selectUserUid)
  const displayName = useSelector(selectUserDisplayName)
  const photoURL = useSelector(selectPhotoURL)

  const [playMashSound] = useSound(sfxButtonPress, { playbackRate: 1.5 })
  const [playResetSound] = useSound(sfxButtonReset, { playbackRate: 1.5 })

  const dispatch = useDispatch()

  const mashButton = () => {
    if (!isMashed) {
      dispatch(requestButtonPress({ uid, displayName, photoURL }))
    }
  }

  const onKeyDown = (event) => {
    const KeyCode = {
      enter: 13,
      space: 32,
    }

    const { keyCode } = event
    if (keyCode === KeyCode.enter || keyCode === KeyCode.space) {
      mashButton()
    }
  }

  useEffect(() => {
    const stateChanged = isMashed !== wasMashedBefore
    if (stateChanged) isMashed ? playMashSound() : playResetSound()
  }, [isMashed, wasMashedBefore, playMashSound, playResetSound])

  return (
    <div className={cx(styles.masherArea, className)}>
      <input
        type="image"
        className={styles.masher}
        alt="masher5000"
        onMouseDown={mashButton}
        onKeyDown={onKeyDown}
        src={isMashed ? masherDown : masherUp}
      />
    </div>
  )
}

export default Masher
