import styles from './JumboForm.module.scss'
import cx from 'classnames'
const noSubmit = (event) => event.preventDefault()

const JumboForm = ({ title = null, children = null, onSubmit = noSubmit, className = '' }) => {
  return (
    <form
      className={cx(
        'border-0 rounded-md bg-black bg-opacity-70 shadow-black shadow-md',
        styles.jumboForm,
        className,
      )}
      onSubmit={onSubmit}
    >
      {title && <h2>{title}</h2>}
      {children}
    </form>
  )
}

export default JumboForm
