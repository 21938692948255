import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getAnalytics, isSupported as isAnalyticsSupported } from 'firebase/analytics'
import {
  AppCheckProvider,
  FirestoreProvider,
  AuthProvider,
  useFirebaseApp,
  StorageProvider,
  useInitPerformance,
  AnalyticsProvider,
} from 'reactfire'
import config from '../config'
import { isProduction } from '../../core/utils/isProduction'

const ProductionOnlyAppCheck = ({ children, app }) => {
  return isProduction() ? (
    <AppCheckProvider
      sdk={initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(config.captchaKey),
        isTokenAutoRefreshEnabled: true,
      })}
    >
      {children}
    </AppCheckProvider>
  ) : (
    children
  )
}

/**
 * These conditionals are so that environments like tests can run without them.
 *
 * Note: Top level await is only supported when target is ES2017 or higher.
 */
const isAnalyticsProviderSupported = await isAnalyticsSupported()
const ConditionalAnalyticsProvider = ({ children }) => {
  // a parent component contains a `FirebaseAppProvider`
  const app = useFirebaseApp()

  if (isAnalyticsProviderSupported) {
    const analytics = getAnalytics(app)
    return <AnalyticsProvider sdk={analytics}>{children}</AnalyticsProvider>
  }

  return children
}

const useConditionalPerformance = isAnalyticsProviderSupported
  ? () => {
      // Test env supported fetch, Promise and cookies, which were the only indicator in the error message when running tests
      useInitPerformance(
        async (app) => {
          const { getPerformance } = await import('firebase/performance')
          return getPerformance(app)
        },
        { suspense: false }, // false because we don't want to stop render while we wait for perf
      )
    }
  : () => {}

/**
 * All Firebase services are initialized here
 * A parent component must contain the `FirebaseAppProvider`
 */
function FirebaseComponents({ children }) {
  const app = useFirebaseApp()
  const firestore = getFirestore(app)
  const auth = getAuth(app)
  const storage = getStorage(app)

  useConditionalPerformance()

  // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
  return (
    <ProductionOnlyAppCheck app={app}>
      <ConditionalAnalyticsProvider>
        <AuthProvider sdk={auth}>
          <FirestoreProvider sdk={firestore}>
            <StorageProvider sdk={storage}>{children}</StorageProvider>
          </FirestoreProvider>
        </AuthProvider>
      </ConditionalAnalyticsProvider>
    </ProductionOnlyAppCheck>
  )
}

export default FirebaseComponents
