import { all, getContext, takeLatest } from 'redux-saga/effects'
import {
  nextQuestionLoaded,
  requestAnsweredCorrectly,
  requestAnsweredIncorrectly,
  requestLoadGame,
  requestLoadNextQuestion,
  requestNextCategory,
  requestPauseQuestion,
  requestResetGame,
  requestResumeQuestion,
  requestStartNextQuestion,
  requestScores,
  requestButtonPress,
  requestAnswerQueueReset,
} from '../ducks/lifecycleDuck'
import { SocketService } from '../../../core/service/socketService'

function* forwardToWebSocket(action) {
  const party: SocketService = yield getContext('partyService')

  party.send(action)
}

export function* gameHostInteractionSaga() {
  yield all([
    takeLatest(
      [
        requestLoadGame,
        requestResetGame,
        requestStartNextQuestion,
        requestPauseQuestion,
        requestNextCategory,
        requestLoadNextQuestion,
        nextQuestionLoaded,
        requestStartNextQuestion,
        requestButtonPress,
        requestAnswerQueueReset,
        requestAnsweredCorrectly,
        requestAnsweredIncorrectly,
        requestPauseQuestion,
        requestResumeQuestion,
        requestScores,
      ],
      forwardToWebSocket,
    ),
  ])
}
