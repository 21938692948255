export const supportedQuizTypes = {
  'music-intros': { title: 'Music intros' },
  'guess-the-city': { title: 'Guess the city' },
  'find-the-differences': { title: 'Find the differences' },
}

export const supportedQuizTypesSlugs = Object.keys(supportedQuizTypes)

const asConst = [...supportedQuizTypesSlugs] as const

export type QuizTypeSlug = (typeof asConst)[number]
