/* eslint-disable react-hooks/exhaustive-deps,unicorn/new-for-builtins */
import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'classnames'
import styles from './Code.module.scss'

interface Props {
  digits: number
  placeholder: string
  onChange: (value: string) => any
  value?: string
  wrapperClassName?: string
  fieldClassName?: string
}

function Code({
  placeholder,
  digits,
  onChange: bubbleChange,
  value = '',
  wrapperClassName = '',
  fieldClassName = '',
}: Props): JSX.Element {
  const [code, setCode] = useState<string>(value)

  const fields = useMemo(() => {
    const indexes = [...Array(digits)].map((_, index) => index)
    return Object.fromEntries(indexes.map((index) => [index, createRef<HTMLInputElement>()]))
  }, [digits])

  const focus = useCallback((nth) => fields[nth].current?.focus(), [fields])
  const onFocus = useCallback(
    () => focus(code.length >= digits ? digits - 1 : code.length),
    [code.length, digits, focus],
  )

  useEffect(() => bubbleChange(code), [code])
  useEffect(() => onFocus(), [])

  const onChange = (event) => {
    const { value } = event.target
    const newValue = value === '' ? code.slice(0, -1) : `${code}${value}`.slice(0, digits)

    setCode(newValue)
    newValue.length === digits ? event.target.blur() : focus(newValue.length)
  }

  const onKeyDown = (event) => {
    switch (event.keyCode) {
      case 8: {
        // backspace, which doesn't trigger onChange when field is empty
        const newValue = code.slice(0, -1)

        setCode(newValue)
        focus(newValue.length)
      }
    }
  }

  return (
    <div onClick={onFocus} className={cx(wrapperClassName, styles.wrapper)}>
      {Object.keys(fields).map((id) => {
        return (
          <input
            key={`digit-${id}`}
            ref={fields[id]}
            value={code[id] || ''}
            placeholder={placeholder[id] || ''}
            onChange={onChange}
            onKeyDown={onKeyDown}
            data-lpignore="true"
            autoComplete="off"
            className={cx(fieldClassName, styles.field)}
          />
        )
      })}
    </div>
  )
}

export default Code
