import styles from './Room.module.scss'
import Screen from './Screen/Screen'
import Masher from './Masher/Masher'
import Secondary from './Secondary/Secondary'
import ProfileIcon from '../../core/ui/reactfire/Auth/Profile/Icon/ProfileIcon'
import Layout from '../../shared/ui/Layout/Layout'
import Page from '../../shared/ui/Page/Page'
import RoomCodeButton from './RoomCode/RoomCodeButton'
import UserContentWrapper from '../../core/ui/reactfire/Auth/UserContentWrapper/UserContentWrapper'
import SignInForm from '../../shared/ui/SignInForm/SignInForm'
import { useEffect } from 'react'
import { useRoomCode } from '../hooks/useRoomCode'
import { useDispatch, useSelector } from 'react-redux'
import { requestToJoinByCode } from '../logic/ducks/connectivityDuck'
import CopyCodeModal from './CopyCodeModal'
import ErrorBoundary from '../../core/ui/react/ErrorBoundary'
import SocialPanel from './Secondary/Social/SocialPanel'
import AdminPanel from './AdminPanel/AdminPanel'
import { selectIsHost } from '../../organiser/logic/ducks/gameDuck'
import ConnectionStatus from './Legacy/Status/ConnectionStatus'
import DeveloperPanel from './AdminPanel/DeveloperPanel'
import { isDevelopment } from '../../core/utils/isProduction'
import {
  selectCurrentCategory,
  selectCurrentQuestion,
  selectHasGameLoaded,
  selectIsScoreDisplayed,
  selectNextQuestion,
} from '../../game/logic/ducks/lifecycleDuck'
import NextQuestionLoader from './Screen/NextQuestionLoader/NextQuestionLoader'
import { Question } from './Screen/Question/Question'
import CategoryDisplay from './CategoryDisplay/CategoryDisplay'
import Scores from './Scores/Scores'
import QuestionInfo from './QuestionInfo/QuestionInfo'
import PreGame from './Screen/PreGame/PreGame'
import GameLoaded from './Screen/GameLoaded/GameLoaded'

interface Props {}

function Room({}: Props): JSX.Element {
  const roomCode = useRoomCode()
  const dispatch = useDispatch()
  const isHost = useSelector(selectIsHost)
  const hasGameLoaded = useSelector(selectHasGameLoaded)
  const currentCategory = useSelector(selectCurrentCategory)
  const nextQuestion = useSelector(selectNextQuestion)
  const currentQuestion = useSelector(selectCurrentQuestion)
  const isScoreDisplayed = useSelector(selectIsScoreDisplayed)

  useEffect(() => {
    if (!roomCode) return
    dispatch(requestToJoinByCode(roomCode))
  }, [dispatch, roomCode])

  return (
    <UserContentWrapper fallback={<SignInForm />}>
      <Page>
        <ErrorBoundary>
          <CopyCodeModal />
        </ErrorBoundary>

        <AdminPanel />
        <DeveloperPanel />

        <Layout className={styles.room}>
          <RoomCodeButton className="absolute top-4 left-4 z-10" />
          {isDevelopment() && <ConnectionStatus className="absolute top-4 z-10 text-xs" />}
          <ProfileIcon className="absolute top-4 right-4 z-10" />

          <Screen>
            {!hasGameLoaded && <PreGame />}
            {hasGameLoaded && !currentCategory && !isScoreDisplayed && <GameLoaded />}
            {nextQuestion && <NextQuestionLoader />}
            {currentQuestion && <Question />}
            {currentCategory && !currentQuestion && <CategoryDisplay />}
            {isScoreDisplayed && <Scores />}
          </Screen>

          <Secondary>
            <Masher className="z-10" />
            <SocialPanel />
            {isHost ? <QuestionInfo /> : null}
          </Secondary>
        </Layout>
      </Page>
    </UserContentWrapper>
  )
}

export default Room
