// This config is publicly exposed, do not add secrets.
const config = {
  name: 'Introgame',
  title: 'Introgame',
  description: 'Mash the buttons, pray for points!',
  domainUrl: 'https://introga.me',
  apiUrl: 'introga.me',
  creatorTwitter: '@webbertakken',
  themeColor: '#628cae',
  firebase: {
    apiKey: 'AIzaSyAmm2Q_XD8YDl45gAgYUjCWrUx4TkMeup8',
    authDomain: 'introgame-webber.firebaseapp.com',
    projectId: 'introgame-webber',
    storageBucket: 'introgame-webber.appspot.com',
    messagingSenderId: '816263354064',
    appId: '1:816263354064:web:ead1269928b8b2676030d5',
    measurementId: 'G-4LTM23HZRK',
  },
  captchaKey: '6Lez_OkdAAAAAMGK9pgzwkLnoAFv0OXau9AbYrIH',
  game: {
    questionsPerRound: 12,
  },
}

export default config
