import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CategoryProps } from '../../model/Types'
import { GameState } from '../../model/Game'
import { NavigateFunction } from 'react-router-dom'
import { gameLoaded } from '../../../game/logic/ducks/lifecycleDuck'
import { isDevelopment } from '../../../core/utils/isProduction'

export interface Game {
  state: GameState
  roomCode: string
  rounds: number
  currentRound: number
  currentQuestion: number
  category1?: Partial<CategoryProps>
  category2?: Partial<CategoryProps>
  category3?: Partial<CategoryProps>
  category4?: Partial<CategoryProps>
  category5?: Partial<CategoryProps>
}

export interface GameSummaryCategory {
  title: string
  quizTypeTitle: string
}

export interface GameSummary {
  name: string
  roomCode: string
  rounds: number
  currentRound: number | null
  currentQuestion: number | null
  category1: GameSummaryCategory
  category2: GameSummaryCategory | null
  category3: GameSummaryCategory | null
  category4: GameSummaryCategory | null
  category5: GameSummaryCategory | null
}

interface State {
  organiser: { game: Game }
}

const initialState: Game = {
  state: 'creating',
  roomCode: null,
  rounds: 1,
  currentRound: null,
  currentQuestion: null,
  category1: null,
  category2: null,
  category3: null,
  category4: null,
  category5: null,
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    numberOfRoundsSet: (state, action: PayloadAction<number>) => {
      state.rounds = action.payload
    },
    categorySelected: (
      state,
      action: PayloadAction<{ index: number; category: Partial<CategoryProps> }>,
    ) => {
      const { index, category } = action.payload

      state[`category${index}`] = category
    },
    roomRequested: (state, action: PayloadAction<{ navigate: NavigateFunction }>) => {},
    roomEntered: (state, action: PayloadAction<{ roomCode: string }>) => {
      state.state = 'enteredRoom'
      state.roomCode = action.payload.roomCode
    },
  },
  extraReducers: (builder) => {
    builder.addCase(gameLoaded, (state, action: Action) => {
      state.state = 'loadedInRoom'
    })
  },
})

// Actions

export const { numberOfRoundsSet, categorySelected, roomRequested, roomEntered } = gameSlice.actions

// Reducer

export default gameSlice.reducer

// Selectors

export const selectGameRoot = (state: State) => state.organiser.game || initialState
export const selectNumberOfRounds = (state: State) => selectGameRoot(state).rounds
export const selectCategory1 = (state: State) => selectGameRoot(state).category1
export const selectCategory2 = (state: State) => selectGameRoot(state).category2
export const selectCategory3 = (state: State) => selectGameRoot(state).category3
export const selectCategory4 = (state: State) => selectGameRoot(state).category4
export const selectCategory5 = (state: State) => selectGameRoot(state).category5
export const selectIsHost = (state: State) =>
  selectGameRoot(state).state !== 'creating' || isDevelopment()
