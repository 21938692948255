import { Action, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

interface ConnectivityState {
  name: string
  isLoading: boolean
  hasJoined: boolean
  isConnected: boolean
  startTime: number | null
  error: string | null
}

interface State {
  room: { connectivity: ConnectivityState }
}

const initialState: ConnectivityState = {
  name: '',
  isLoading: false,
  hasJoined: false,
  isConnected: false,
  startTime: null,
  error: null,
}

export const connectivitySlice = createSlice({
  name: 'connectivity',
  initialState,
  reducers: {
    requestToJoinByCode(state, action: PayloadAction<string>) {
      state.isLoading = true
      state.name = action.payload
    },
    joined: (state: Draft<ConnectivityState>) => {
      state.isLoading = false
      state.hasJoined = true
      state.error = null
    },
    failedToJoin: (state: Draft<ConnectivityState>, action: PayloadAction<string>) => {
      state.isLoading = false
      state.error = action.payload
    },
    socketConnected: (state, action: Action) => {
      state.isConnected = true
      state.startTime = Date.now()
    },
    socketDisconnected: (state, action: Action) => {
      state.isConnected = false
    },
    requestReconnect: () => {},
    ready: (state, action: Action) => {},
  },
})

// Actions

export const {
  requestToJoinByCode,
  joined,
  failedToJoin,
  ready,
  socketConnected,
  socketDisconnected,
  requestReconnect,
} = connectivitySlice.actions

// Reducer

export default connectivitySlice.reducer

// Selectors

export const selectConnectivity = (state: State) => state.room.connectivity || initialState
export const selectRoomName = (state: State) => selectConnectivity(state).name
export const selectIsConnected = (state: State) => selectConnectivity(state).isConnected
export const selectHasJoinedRoom = (state: State) => selectConnectivity(state).hasJoined
export const selectIsLoadingRoom = (state: State) => selectConnectivity(state).isLoading
export const selectError = (state: State) => selectConnectivity(state).error
