import { Action, createSlice } from '@reduxjs/toolkit'

const initialisationSlice = createSlice({
  name: 'init',
  initialState: {},
  reducers: {
    initialisationRequested: (state, action: Action) => {},
  },
})

export const { initialisationRequested } = initialisationSlice.actions

export default initialisationSlice.reducer
