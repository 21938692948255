import CozyButton from '../../../shared/ui/Buttons/CozyButton'
import { useDispatch, useSelector } from 'react-redux'
import { requestReconnect } from '../../logic/ducks/connectivityDuck'
import { useNavigate } from 'react-router-dom'
import { selectIsHost } from '../../../organiser/logic/ducks/gameDuck'

interface Props {}

function DeveloperPanel({}: Props): JSX.Element {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const isHost = useSelector(selectIsHost)

  const reconnect = (event) => {
    event.preventDefault()
    dispatch(requestReconnect())
  }

  const backToStartGame = (event) => {
    event.preventDefault()
    navigate('/organiser/start-game')
  }

  return isHost ? (
    <div className="z-20 absolute bottom-2 left-2 bg-black bg-opacity-70 p-2 rounded-md flex flex-col gap-1">
      <CozyButton onClick={reconnect}>Reconnect</CozyButton>
      <CozyButton onClick={backToStartGame}>Start new game</CozyButton>
    </div>
  ) : null
}

export default DeveloperPanel
