import { Link } from 'react-router-dom'
import cx from 'classnames'
import { useRoomCode } from '../../hooks/useRoomCode'

interface Props {
  className?: string
}

function RoomCodeButton({ className }: Props): JSX.Element {
  const roomCode = useRoomCode()

  return (
    <Link
      to="#copy-code"
      replace
      className={cx(
        'select-none focus:ring-1 focus:ring-offset-1 focus:ring-indigo-600 focus:outline-none mx-auto transition duration-150 ease-in-out hover:bg-indigo-600 bg-indigo-700 rounded-sm text-white px-4 py-1 text-xs',
        className,
      )}
    >
      Room: {roomCode}
    </Link>
  )
}

export default RoomCodeButton
