import { useAnalytics } from 'reactfire'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { logEvent, isSupported as isAnalyticsSupported } from 'firebase/analytics'

// Top level await is only supported when target is ES2017 or higher.
const isAnalyticsProviderSupported = await isAnalyticsSupported()

const PageViewLogger = () => {
  const analytics = useAnalytics()
  const { pathname } = useLocation()

  useEffect(() => {
    logEvent(analytics, 'page-view', { page_path: pathname })
  }, [analytics, pathname])

  return null
}

export default isAnalyticsProviderSupported ? PageViewLogger : () => null
