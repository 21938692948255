import { useDispatch } from 'react-redux'
import CozyButton from '../../../../shared/ui/Buttons/CozyButton'
import { useCallback } from 'react'
import { roomRequested } from '../../../logic/ducks/gameDuck'
import { useNavigate } from 'react-router-dom'

interface Props {}

function StartButton({}: Props): JSX.Element {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const requestRoom = useCallback(
    (event) => {
      event.preventDefault()
      dispatch(roomRequested({ navigate }))
    },
    [dispatch, navigate],
  )

  return (
    <div className="w-full p-6 flex flex-row">
      <div className="flex-grow" />
      <CozyButton
        onClick={requestRoom}
        primary
        className="text-xs mr-2 sm:text-md md:text-lg lg:text-2xl"
      >
        Start
      </CozyButton>
    </div>
  )
}

export default StartButton
