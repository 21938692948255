import CozyButton from '../../../shared/ui/Buttons/CozyButton'
import {
  requestScores,
  requestLoadGame,
  requestNextCategory,
  requestResetGame,
  requestStartNextQuestion,
  selectHasGameLoaded,
  selectIsLastCategory,
  selectIsLastQuestion,
  selectIsQuestionReady,
  requestResumeQuestion,
  requestPauseQuestion,
  selectIsQuestionPaused,
  selectCurrentQuestion,
  selectIsQuestionRevealed,
  requestAnswerQueueReset,
} from '../../../game/logic/ducks/lifecycleDuck'
import { selectGameRoot, selectIsHost } from '../../../organiser/logic/ducks/gameDuck'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { isDevelopment } from '../../../core/utils/isProduction'

interface Props {}

function AdminPanel({}: Props): JSX.Element {
  const dispatch = useDispatch()
  const gameRoot = useSelector(selectGameRoot)
  const hasGameLoaded = useSelector(selectHasGameLoaded)
  const isLastCategory = useSelector(selectIsLastCategory)
  const isLastQuestion = useSelector(selectIsLastQuestion)
  const isQuestionReady = useSelector(selectIsQuestionReady)
  const isQuestionPaused = useSelector(selectIsQuestionPaused)
  const currentQuestion = useSelector(selectCurrentQuestion)
  const isQuestionRevealed = useSelector(selectIsQuestionRevealed)
  const isHost = useSelector(selectIsHost)

  const handler = (callback) => (event) => {
    event.preventDefault()
    callback()
  }

  const pausePlayback = handler(() => {
    dispatch(requestPauseQuestion())
  })

  const resumePlayback = handler(() => {
    dispatch(requestResumeQuestion())
  })

  const showScores = handler(() => {
    dispatch(requestScores())
  })

  const resetButtons = handler(() => {
    dispatch(requestAnswerQueueReset())
  })

  const startQuestion = handler(() => {
    dispatch(requestStartNextQuestion())
  })

  const nextCategory = handler(() => {
    dispatch(requestNextCategory())
  })

  const loadGame = handler(() => {
    dispatch(requestLoadGame(gameRoot))
  })

  const resetGame = handler(() => {
    dispatch(requestResetGame())
  })

  return isHost ? (
    <div className="z-30 absolute bottom-2 right-2 bg-black bg-opacity-70 p-2 rounded-md flex flex-col gap-1">
      {hasGameLoaded ? (
        <>
          {currentQuestion ? (
            <>
              {isQuestionPaused ? (
                <CozyButton primary disabled={!isQuestionRevealed} onClick={resumePlayback}>
                  Resume
                </CozyButton>
              ) : (
                <CozyButton secondary disabled={!isQuestionRevealed} onClick={pausePlayback}>
                  Pause
                </CozyButton>
              )}
            </>
          ) : null}

          <CozyButton disabled={isLastQuestion && isLastCategory} onClick={showScores}>
            Show scores
          </CozyButton>
          <CozyButton onClick={resetButtons}>Reset buttons</CozyButton>
          <CozyButton warning={!isQuestionReady} disabled={isLastQuestion} onClick={startQuestion}>
            Next question
          </CozyButton>
          <CozyButton disabled={isLastCategory} onClick={nextCategory}>
            Next category
          </CozyButton>
        </>
      ) : (
        <CozyButton onClick={loadGame}>Load game</CozyButton>
      )}

      <CozyButton warning onClick={resetGame}>
        Reset game
      </CozyButton>
    </div>
  ) : null
}

export default AdminPanel
