import { useDispatch, useSelector } from 'react-redux'
import styles from './MashersQueue.module.scss'
import {
  requestAnsweredCorrectly,
  requestAnsweredIncorrectly,
  selectAnswerQueue,
} from '../../../../game/logic/ducks/lifecycleDuck'
import { useCallback } from 'react'
import cx from 'classnames'
import { selectIsHost } from '../../../../organiser/logic/ducks/gameDuck'

const MasherQueue = () => {
  const queue = useSelector(selectAnswerQueue)
  const isHost = useSelector(selectIsHost)
  const dispatch = useDispatch()

  const correctAnswer = useCallback(
    (uid, points) => {
      dispatch(requestAnsweredCorrectly({ player: { uid }, points }))
    },
    [dispatch],
  )

  const incorrectAnswer = useCallback(
    (uid, points) => {
      dispatch(requestAnsweredIncorrectly({ player: { uid }, points }))
    },
    [dispatch],
  )

  if (!queue) return null

  return (
    <div className={cx('z-9', styles.mashersQueue)}>
      {queue.map((candidate, index) => {
        const { hasAnswered, wasAnswerCorrect, player } = candidate
        const { uid, displayName, photoURL } = player

        return (
          <div
            key={index}
            className={cx(
              'relative flex flex-row gap-2 z-20 items-center justify-center',
              styles.candidate,
            )}
            style={{ backgroundImage: `url('${photoURL}')` }}
          >
            {hasAnswered ? (
              <div className="absolute inset-auto text-4xl">{wasAnswerCorrect ? '✅' : '❌'}</div>
            ) : null}

            <div className="absolute bottom-0 bg-black w-full rounded-md p-1 leading-none">
              {displayName}
            </div>

            {isHost ? (
              <>
                <button
                  className="absolute left-[5%] text-xs cursor-pointer"
                  onClick={() => incorrectAnswer(uid, -1)}
                >
                  👎
                </button>

                <button
                  className="absolute right-[5%] text-xs cursor-pointer"
                  onClick={() => correctAnswer(uid, 1)}
                >
                  👍
                </button>
              </>
            ) : null}
          </div>
        )
      })}
    </div>
  )
}

export default MasherQueue
