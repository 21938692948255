import ProfileModal from '../../../core/ui/reactfire/Auth/Profile/Modal/ProfileModal'
import { ReactElement } from 'react'
import ErrorBoundary from '../../../core/ui/react/ErrorBoundary'

interface Props {
  children?: ReactElement | ReactElement[]
}

function Page({ children }: Props): JSX.Element {
  return (
    <ErrorBoundary>
      <ProfileModal />
      {children}
    </ErrorBoundary>
  )
}

export default Page
