import { Link, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import {
  DottedLineItem,
  DottedLineGroup,
} from '../../../../shared/ui/DirectoryStructure/DottedLineGroup'
import cx from 'classnames'
import { CategoryProps } from '../../../model/Types'
import DeleteCategory from './DeleteCategory'
import Dropzone from './Upload/Dropzone'
import Questions from './Question/Questions'

function Category({ title, slug }: Partial<CategoryProps>): JSX.Element {
  const { category: currentSlug } = useParams()
  const isActive = useMemo(() => slug === currentSlug, [slug, currentSlug])
  const to = useMemo(() => (isActive ? '../' : `../${slug}`), [slug, isActive])

  return (
    <DottedLineItem
      className={cx('py-2 bg-opacity-20 rounded-md hover:bg-black hover:bg-opacity-25', {
        'bg-black': isActive,
      })}
    >
      <Link to={to} className="flex flex-row group">
        <span> {title}</span>
        <DeleteCategory slug={slug} className="ml-auto invisible group-hover:!visible" />
      </Link>
      {isActive && (
        <DottedLineGroup className="p-2 pt-0 pr-4" lineClassName="border-opacity-50">
          <DottedLineItem className="pt-2" lineClassName="border-opacity-50 mt-9">
            <Dropzone
              className="-ml-2 bg-[#354660] rounded-2xl"
              dropAreaClassName="rounded-2xl border-dotted border-2 border-opacity-30 p-6 cursor-pointer"
              previewsClassName="rounded-2xl border-dotted border-0 border-opacity-30 p-6"
            />
          </DottedLineItem>

          <Questions category={slug} />
        </DottedLineGroup>
      )}
    </DottedLineItem>
  )
}

export default Category
