import { createRef, useEffect, useState } from 'react'
import styles from './Resizer.module.scss'

export function useResizer(wrapperRef) {
  const selfRef = createRef<HTMLDivElement>()
  const otherRef = createRef<HTMLDivElement>()

  const [isResizing, setIsResizing] = useState<boolean>(false)

  useEffect(() => {
    const mouseUp = () => {
      setIsResizing(false)
    }

    const mouseMove = (event) => {
      if (!isResizing) return false

      const totalSpace = wrapperRef.current?.getBoundingClientRect().height
      const maxSize = totalSpace / 2
      const wrapperStartYPos = wrapperRef.current?.offsetTop || 0
      const pointerYPos = event.clientY - wrapperStartYPos

      const height = Math.min(maxSize, Math.max(50, totalSpace - pointerYPos))

      if (otherRef.current) {
        otherRef.current.style.height = `${height}px`
        otherRef.current.style.flexGrow = `0`
      }
    }

    window.addEventListener('mouseup', mouseUp)
    window.addEventListener('mousemove', mouseMove)

    return () => {
      window.removeEventListener('mouseup', mouseUp)
      window.removeEventListener('mousemove', mouseMove)
    }
  })

  const Resizer = (props) => (
    <div
      ref={selfRef}
      {...props}
      className={styles.resizer}
      onMouseDown={() => setIsResizing(true)}
      onMouseUp={() => setIsResizing(false)}
    >
      <hr />
      <div className={styles.handle} />
    </div>
  )

  return { Resizer, otherRef }
}
