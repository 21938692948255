import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { gameLoaded, nextQuestionStarted } from './lifecycleDuck'

export type FileType = null | 'image/jpeg' | 'audio/wave' | 'audio/mpeg' | 'video/quicktime'

export interface QuestionInfo {
  type: FileType
  url: string
}

export type QuestionPhase =
  | 'none'
  | 'loading'
  | 'countingDown'
  | 'revealed'
  | 'buttonPressed'
  | 'answering'
  | 'fadingOut'

interface QuestionState {
  info: QuestionInfo
  phase: QuestionPhase
}

interface State {
  game: { question: QuestionState }
}

const initialState: QuestionState = {
  info: {
    type: null,
    url: '',
  },
  phase: 'none',
}

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    loaded: (state, action: PayloadAction<any>) => {
      state.info = action.payload
      state.phase = 'countingDown'
    },
    revealed: (state, action: Action) => {
      state.phase = 'revealed'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(nextQuestionStarted, (state, action: Action) => {
      state.phase = 'loading'
    })
    builder.addCase(gameLoaded, (state, action: Action) => {
      return initialState
    })
  },
})

// Actions

export const { loaded, revealed } = questionSlice.actions

// Reducer

export default questionSlice.reducer

// Selectors

export const selectQuestionRoot = (state: State) => state.game.question || initialState
export const selectQuestionInfo = (state: State) => selectQuestionRoot(state).info
export const selectQuestionPhase = (state: State) => selectQuestionRoot(state).phase
export const selectIsQuestionActive = (state: State) => selectQuestionPhase(state) !== 'none'
