import ExplanationHeader from './ExplanationHeader/ExplanationHeader'
import SelectedCategories from './SelectedCategories/SelectedCategories'
import StartButton from './StartButton/StartButton'

interface Props {}

function StartGame({}: Props): JSX.Element {
  return (
    <div className="py-6">
      <ExplanationHeader />
      <SelectedCategories />
      <StartButton />
    </div>
  )
}

export default StartGame
