import HashModal from '../../core/ui/tailwind/Modal/HashModal'
import { useClipboard } from '../../core/hooks/useClipboard'
import { useNotification } from '../../core/hooks/useNotification'
import { useRoomUrl } from '../hooks/useRoomUrl'

interface Props {}

const CopyCodeModal = ({}: Props) => {
  const clipboard = useClipboard()
  const roomUrl = useRoomUrl()

  const Content = () => {
    const notify = useNotification()

    const copyToClipboard = () => {
      notify.promise(clipboard.write(roomUrl), {
        loading: 'copying to clipboard',
        success: 'The link was copied to your clipboard',
        error: 'Unable to copy the link to your clipboard',
      })
    }

    return (
      <>
        <p className="mt-0 pb-2 select-none">
          Share this link with the people you'd like to participate in this game.
        </p>

        <div>
          <div className="flex items-center h-full justify-between text-sm font-semibold leading-6 bg-gray-100  rounded h-12 w-full">
            <div>
              <p className="pl-4 text-base leading-tight text-gray-900">{roomUrl}</p>
            </div>
            <button
              onClick={copyToClipboard}
              className="bg-indigo-700 p-3.5 rounded-r cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M16.6667 7.5H9.16667C8.24619 7.5 7.5 8.24619 7.5 9.16667V16.6667C7.5 17.5871 8.24619 18.3333 9.16667 18.3333H16.6667C17.5871 18.3333 18.3333 17.5871 18.3333 16.6667V9.16667C18.3333 8.24619 17.5871 7.5 16.6667 7.5Z"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.16675 12.5H3.33341C2.89139 12.5 2.46746 12.3244 2.1549 12.0119C1.84234 11.6993 1.66675 11.2754 1.66675 10.8334V3.33335C1.66675 2.89133 1.84234 2.4674 2.1549 2.15484C2.46746 1.84228 2.89139 1.66669 3.33341 1.66669H10.8334C11.2754 1.66669 11.6994 1.84228 12.0119 2.15484C12.3245 2.4674 12.5001 2.89133 12.5001 3.33335V4.16669"
                  stroke="white"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <HashModal
      hash="#copy-code"
      title="Share the code"
      size="sm"
      className="select-none"
      Content={Content}
    />
  )
}

export default CopyCodeModal
