import JumboForm from '../JumboForm/JumboForm'
import JumboFormPage from '../JumboForm/JumboFormPage'
import AuthProviderButton from '../../../core/ui/reactfire/Auth/ProviderButtons/ProviderButton'
import CozyButton from '../Buttons/CozyButton'
import { supportedProviders } from '../../../core/ui/reactfire/Auth/supportedProviders'

const SignInForm = () => {
  return (
    <JumboFormPage>
      <h1>Sign in 💻</h1>
      <JumboForm title="Choose your identity provider" className="columns-2">
        {supportedProviders.map(({ id, ...provider }) => (
          <AuthProviderButton key={id} id={id} {...provider} button={CozyButton} />
        ))}
      </JumboForm>
    </JumboFormPage>
  )
}

export default SignInForm
