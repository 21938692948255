import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { requestToJoinByCode, selectError } from '../../../../room/logic/ducks/connectivityDuck'
import { useNavigate } from 'react-router-dom'
import JumboForm from '../../JumboForm/JumboForm'
import Code from './Code'
import CozyButton from '../../Buttons/CozyButton'

export const JoinOrHostStep = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const input = useRef<HTMLInputElement>()
  const error = useSelector(selectError)
  const [partyCode, setPartyCode] = useState<string>('')

  const onChange = (value: string) => {
    setPartyCode(value)
  }

  const onSubmitJoin = (event) => {
    event.preventDefault()
    if (partyCode.length === 6) {
      navigate(`/room/${partyCode}`)
    }
  }

  const onSubmitHost = (event) => {
    event.preventDefault()
    navigate('/organiser/inventory')
  }

  useEffect(() => {
    if (!input.current) return
    input.current.focus()
  }, [input])

  return (
    <>
      <JumboForm title="🎉 Join your party 🎉" onSubmit={onSubmitJoin}>
        <Code digits={6} onChange={onChange} placeholder="abcxyz" />

        {(error && <p className="error">{error}</p>) || (
          <p className="info">
            enter the <strong>code</strong> to join
          </p>
        )}

        <CozyButton primary>Join</CozyButton>
      </JumboForm>

      <JumboForm title="🍰 Organise your own 🍰" onSubmit={onSubmitHost}>
        <CozyButton secondary>Host</CozyButton>
      </JumboForm>
    </>
  )
}
