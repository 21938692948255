import { combineReducers } from '@reduxjs/toolkit'
import { all } from 'redux-saga/effects'
import connectivity from './ducks/connectivityDuck'
import players from './ducks/playersDuck'
import chat from './ducks/chatDuck'
import { connectionSaga } from './sagas/connectionSaga'
import { chatSaga } from './sagas/chatSaga'

export const room = combineReducers({ connectivity, players, chat })

export function* roomSaga() {
  yield all([connectionSaga(), chatSaga()])
}
