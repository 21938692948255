import { all } from 'redux-saga/effects'
import { combineReducers } from '@reduxjs/toolkit'
import lifecycle from './ducks/lifecycleDuck'
import question from './ducks/questionDuck'
import button from './ducks/buttonDuck'
import { gameHostInteractionSaga } from './sagas/hostInteractionsSaga'
import { questionSaga } from './sagas/questionSaga'

export const game = combineReducers({ lifecycle, question, button })

export function* gameSaga() {
  yield all([gameHostInteractionSaga(), questionSaga()])
}
