import { Suspense } from 'react'
import { DottedLineItem } from '../../../../../shared/ui/DirectoryStructure/DottedLineGroup'
import QuestionUpload from './QuestionUpload'
import Inventory from '../../../../model/Inventory'
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire'
import { collection, orderBy, query } from 'firebase/firestore'

interface Props {
  category: string
}

function Questions({ category }: Props): JSX.Element {
  const { data: user } = useUser()
  const firestore = useFirestore()
  const questionsCollection = collection(firestore, Inventory.getQuestionsPath(user.uid, category))
  const questionsQuery = query(questionsCollection, orderBy('id', 'asc'))
  const { data: questions } = useFirestoreCollectionData(questionsQuery, {
    // @ts-ignore
    idField: `id-${user.stsTokenManager.expirationTime}`,
  })

  return (
    <>
      <Suspense
        fallback={
          <DottedLineItem className="items-center pt-1" lineClassName="border-opacity-50 !mt-0">
            Loading...
          </DottedLineItem>
        }
      >
        {questions?.map((props) => (
          <DottedLineItem key={props.slug} className="pt-1" lineClassName="border-opacity-50 mt-3">
            <QuestionUpload {...props} />
          </DottedLineItem>
        ))}
      </Suspense>
    </>
  )
}

export default Questions
