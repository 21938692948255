import cx from 'classnames'
import styles from './Logo.module.scss'

interface Props {
  className?: string
}

function Logo({ className }: Props): JSX.Element {
  return (
    <h1 className={cx(styles.logo, className)}>
      <span>I</span>
      <span>N</span>
      <span>T</span>
      <span>R</span>
      <span>O</span>
      <span>G</span>
      <span>A</span>
      <span>M</span>
      <span>E</span>
    </h1>
  )
}

export default Logo
