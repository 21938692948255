import { useSelector } from 'react-redux'
import {
  selectCurrentQuestion,
  selectIsQuestionPaused,
  selectIsQuestionRevealed,
} from '../../../../game/logic/ducks/lifecycleDuck'
import { createRef, useCallback, useEffect } from 'react'
import cx from 'classnames'
import { isDevelopment } from '../../../../core/utils/isProduction'

export const Question = () => {
  const question = useSelector(selectCurrentQuestion)
  const isRevealed = useSelector(selectIsQuestionRevealed)
  const audioReference = createRef<HTMLAudioElement>()
  const isPaused = useSelector(selectIsQuestionPaused)

  const play = useCallback(() => {
    const player = audioReference.current
    player?.play()
  }, [audioReference])

  const pause = useCallback(() => {
    const player = audioReference.current
    player?.pause()
  }, [audioReference])

  useEffect(() => {
    if (isRevealed && !isPaused) {
      play()
    } else {
      pause()
    }
  }, [isRevealed, isPaused, play, pause, audioReference.current?.paused])

  if (!question) return null

  const { type, url } = question

  return (
    <>
      <audio
        ref={audioReference}
        src={url}
        controls
        className={cx('absolute z-10 top-16', { hidden: !isDevelopment() || !isRevealed })}
      />
      {isRevealed ? (
        <div className="w-full h-full bg-gray-900 flex flex-col items-center justify-center gap-3">
          <div className="text-green-600 text-3xl sm:text-7xl">🎵</div>
          <div className="absolute bottom-[17%] text-md sm:text-xl lg:text-2xl">
            What's the <u>title</u> and <u>artist</u>?
          </div>
        </div>
      ) : (
        <div className="text-3xl sm:text-7xl">Get ready!...</div>
      )}
    </>
  )
}
