import styles from './InventoryPage.module.scss'
import Dropzone from './Dropzone'
import { createRef, useState } from 'react'
import cx from 'classnames'
import { animated, config, useTransition } from '@react-spring/web'
import { useResizer } from './Resizer/useResizer'

const InventoryPage = () => {
  const wrapperRef = createRef<HTMLDivElement>()
  const [shown, setShown] = useState<{ [key: string]: boolean }>({
    Music: true,
    Video: false,
    Other: false,
  })

  const { Resizer, otherRef } = useResizer(wrapperRef)

  const transitions = useTransition(Object.keys(shown), {
    from: { opacity: '0', display: 'none' },
    leave: { opacity: '0', display: 'none' },
    enter: (item) => [
      { display: shown[item] ? 'flex' : 'none' },
      { opacity: shown[item] ? '1' : '0' },
    ],
    update: (item) => [
      { display: shown[item] ? 'flex' : 'none' },
      { opacity: shown[item] ? '1' : '0' },
    ],

    delay: 200,
    config: config.slow,
  })

  const onClick = (event) => {
    event.preventDefault()
    const { id } = event.target
    setShown({ ...shown, [id]: !shown[id] })
  }

  return (
    <div ref={wrapperRef} className={styles.page}>
      <h2>Inventory</h2>
      <div className={styles.categoryTabs}>
        {Object.entries(shown).map(([id, isShown]) => (
          <button key={id} id={id} className={cx({ [styles.active]: isShown })} onClick={onClick}>
            {id}
          </button>
        ))}
      </div>
      <div className={styles.categories}>
        {transitions(({ opacity, display }, item) => {
          return (
            <animated.div key={item} className={styles.category} style={{ opacity, display }}>
              <h3 className={styles.title}>{item}</h3>
              <Dropzone className={styles.dropzone} />
            </animated.div>
          )
        })}
      </div>
      <Resizer />
      <div ref={otherRef} className={styles.gameStructure}>
        structure
      </div>
    </div>
  )
}

export default InventoryPage
