import styles from './Dropzone.module.scss'
import { useEffect, useMemo, useReducer } from 'react'
import { useDropzone } from 'react-dropzone'
import cx from 'classnames'
import { useFirebaseDrop } from '../../../../core/hooks/useFirebaseDrop'
import AudioQuestion from '../../../../shared/ui/Question/AudioQuestion'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = []

interface FileUpload extends File {
  preview: string
  uploadTask?: any
  storageRef?: any
}

const fileUploadSlice = createSlice({
  name: 'fileUploads',
  initialState,
  reducers: {
    acceptFiles: (state, action: PayloadAction<Array<FileUpload>>) => {
      state.push(...action.payload.map((member) => Object.assign(member, { uploaded: false })))
    },
    uploadComplete: (state, action: PayloadAction<string>) => {
      return state.map((member) => {
        if (member.name === action.payload) {
          delete member.uploadTask
          member.uploaded = true
        }
        return member
      })
    },
  },
})

const { acceptFiles, uploadComplete } = fileUploadSlice.actions

interface Props {
  className?: string
}

const Dropzone = ({ className }: Props) => {
  const [files, dispatch] = useReducer(fileUploadSlice.reducer, initialState)

  const onDrop = useFirebaseDrop(
    // Todo - this is now broken
    'path',
    'path',
    (x) => dispatch(acceptFiles(x)),
    // Todo - this is a bit outdated now
    (x) => dispatch(uploadComplete(x)),
    (x) => dispatch(uploadComplete(x)),
  )

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    accept: ['image/*', 'video/*', 'audio/*'],
    onDrop,
  })

  const previews = useMemo(
    () =>
      files.map((file, index) => {
        const { type, name, preview, uploaded } = file

        if (type.startsWith('audio') || type.startsWith('video')) {
          return <AudioQuestion key={index} uploaded={uploaded} displayAs={'upload'} file={file} />
        }

        return (
          <div className={styles.preview} key={index}>
            <div className={styles.thumb}>
              <img alt={name} src={preview} />
            </div>
          </div>
        )
      }),
    [files],
  )

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      for (const file of files) URL.revokeObjectURL(file.preview)
    },
    [files],
  )

  const modifierClasses = cx({
    [styles.dragActive]: isDragActive,
    [styles.dragAccept]: isDragAccept,
    [styles.dragReject]: isDragReject,
  })

  return (
    <section className={cx(className, styles.dropzone)}>
      <div className={cx(styles.zone, modifierClasses)}>
        <div {...getRootProps({ className: styles.inner })}>
          <input {...getInputProps()} />
          <p>Drag and drop to upload. Click to browse.</p>
        </div>
      </div>
      <aside className={styles.previews}>{previews}</aside>
    </section>
  )
}

export default Dropzone
