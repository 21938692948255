import { all, take, actionChannel, takeEvery, getContext } from 'redux-saga/effects'
import { readMessage, sendMessage } from '../ducks/chatDuck'
import { SocketService } from '../../../core/service/socketService'

function* watchIncomingChatMessages() {
  const channel = yield actionChannel(readMessage)
  while (true) {
    // Action channel has a buffer, we can do blocking calls after.
    const chatMessage = yield take(channel)
  }
}

function* handleSendChatMessage({ payload: message }) {
  const party: SocketService = yield getContext('partyService')

  // Send message to be broadcast by server
  party.send(readMessage(message))
}

export function* chatSaga() {
  yield all([watchIncomingChatMessages(), takeEvery(sendMessage, handleSendChatMessage)])
}
