import styles from './Screen.module.scss'
import Logo from '../../../shared/ui/Logo/Logo'
import { SuspenseWithPerf } from 'reactfire'
import { ReactNode } from 'react'
import { Loading } from '../../../shared/ui/Loading/Loading'
import MasherQueue from './MasherQueue/MasherQueue'

interface Props {
  children?: ReactNode
}

function Screen({ children }: Props): JSX.Element {
  return (
    <div className={styles.wrapper}>
      <div className={styles.screen}>
        <MasherQueue />
        {children ? (
          <SuspenseWithPerf
            fallback={<Loading name="question" />}
            traceId={'screen-content'}
            children={children}
          />
        ) : (
          <Logo />
        )}
      </div>
    </div>
  )
}

export default Screen
