import { HTMLProps, ReactNode } from 'react'
import cx from 'classnames'

interface Props extends HTMLProps<HTMLButtonElement> {
  children?: ReactNode
  primary?: boolean
  secondary?: boolean
  warning?: boolean
  className?: string
}

function CozyButton({
  children,
  primary,
  disabled,
  secondary,
  warning,
  className,
  ...rest
}: Props): JSX.Element {
  return (
    // @ts-ignore
    <button
      className={cx(
        'py-[0.5em] px-[1.5em] border-[1px] border-solid border-white rounded-md flex flex-row items-center justify-center gap-x-2 cursor-pointer',
        { 'bg-[#41c43c] bg-opacity-90 text-[#071807]': primary },
        { 'bg-[#4b93ff] bg-opacity-90 text-[#071807]': secondary },
        { 'border-[#db321b] text-[#db321b]': warning },
        { 'border-gray-800 text-gray-700 cursor-not-allowed': disabled },
        className,
      )}
      disabled={disabled}
      {...rest}
    >
      {children}
    </button>
  )
}

export default CozyButton
