import { ReactNode } from 'react'
import styles from './Secondary.module.scss'

interface Props {
  children: ReactNode
}

function Secondary({ children }: Props): JSX.Element {
  return <div className={styles.secondary}>{children}</div>
}

export default Secondary
