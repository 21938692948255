import { Link } from 'react-router-dom'
import { useSigninCheck } from 'reactfire'
import ProfileIcon from '../../../core/ui/reactfire/Auth/Profile/Icon/ProfileIcon'

export function ProfileCard() {
  const { status, data } = useSigninCheck()

  if (status === 'loading') return <p>Loading.</p>
  if (!data?.signedIn) return <p>Not signed in</p>

  const { displayName } = data.user
  return (
    <div className="flex items-center mt-48 mb-4 px-8">
      <div className="group flex">
        <ProfileIcon className="relative mr-3" />
        <div>
          <Link
            to="#profile"
            replace
            className="block text-gray-400 group-hover:text-gray-300 text-sm font-medium"
          >
            {displayName}
          </Link>
          <Link
            to="#profile"
            replace
            className="block text-gray-500 group-hover:text-gray-400 text-xs"
          >
            View Profile
          </Link>
        </div>
      </div>
      <div className="flex-grow" />
    </div>
  )
}
