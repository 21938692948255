import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App'
import reportWebVitals from './core/utils/reportWebVitals'
import { Provider } from 'react-redux'
import { setupStore } from './store'
import { FirebaseAppProvider } from 'reactfire'
import config from './shared/config'
import './index.scss'
import ErrorBoundary from './core/ui/react/ErrorBoundary'
import FirebaseComponents from './shared/ui/FirebaseComponents'
import { authStatusChanged } from './user/logic/ducks/profileDuck'
import { AuthenticationStateListener } from './core/ui/reactfire/Auth/AuthenticationStateListener'

const store = setupStore()

ReactDOM.createRoot(document.querySelector('#app')).render(
  <React.StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <FirebaseAppProvider firebaseConfig={config.firebase} suspense={true}>
          <FirebaseComponents>
            <AuthenticationStateListener actionCreator={authStatusChanged} />
            <App />
          </FirebaseComponents>
        </FirebaseAppProvider>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
