import cx from 'classnames'
import { useState } from 'react'
import styles from './Social.module.scss'
import Players from './Players'
import Emojis from './Emojis'
import ChatLog from './ChatLog'
import ChatInput from './ChatInput'

const SocialPanel = () => {
  const [message, setMessage] = useState<string>('')
  const [isEmojisOpen, setIsEmojisOpen] = useState<boolean>(false)
  const [isPlayersOpen, setIsPlayersOpen] = useState<boolean>(false)

  const toggleEmojis = () => {
    setIsEmojisOpen(!isEmojisOpen)
  }

  const togglePlayers = () => {
    setIsPlayersOpen(!isPlayersOpen)
  }

  const typeFn = (input: string) => {
    setMessage(`${message}${input}`)
  }

  return (
    <div className={styles.socialPanel}>
      <div className={styles.wrapper}>
        <Emojis className={cx(styles.emojis, { [styles.hidden]: !isEmojisOpen })} typeFn={typeFn} />
        <ChatLog />
        <Players className={cx(styles.players, { [styles.hidden]: !isPlayersOpen })} />
      </div>

      <div className={styles.chatInputWrapper}>
        <button type="button" onClick={toggleEmojis}>
          🙂
        </button>
        <ChatInput message={message} setMessage={setMessage} />
        <button type="button" onClick={togglePlayers}>
          👤
        </button>
      </div>
    </div>
  )
}

export default SocialPanel
