import { useState } from 'react'
import { useFirestore, useUser } from 'reactfire'
import { useNotification } from '../../../../core/hooks/useNotification'
import { setDoc, doc, getDoc, DocumentReference } from 'firebase/firestore'
import { CategoryProps } from '../../../model/Types'
import Inventory from '../../../model/Inventory'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { slugify } from '../../../../core/utils/slugify'
import { supportedQuizTypes } from '../../../model/supportedQuizTypes'

interface Props extends Partial<CategoryProps> {
  className?: string
  formClassName?: string
}

const NewCategory = ({ className, formClassName, quizType }: Props) => {
  const { data: user } = useUser()
  const [name, setName] = useState<string>('')
  const firestore = useFirestore()
  const notify = useNotification()
  const collectionPath = Inventory.getCategoriesPath(user.uid)
  const navigate = useNavigate()

  const createNewCategory = async (event) => {
    event.preventDefault()
    const slug = slugify(name)

    const createIfNotExists = async () => {
      const documentReference = doc(
        firestore,
        collectionPath,
        slug,
      ) as DocumentReference<CategoryProps>
      const document = await getDoc(documentReference)

      if (document.exists()) {
        throw new Error('Category already exists.')
      }

      await setDoc(documentReference, {
        id: slug,
        slug,
        title: name,
        quizType,
        quizTypeTitle: supportedQuizTypes[quizType].title,
      })

      navigate(`../${slug}`)
      setName('')
    }

    await notify.promise(createIfNotExists(), {
      loading: 'Adding category.',
      error: (error) => `An error occurred. ${error.message}`,
      success: `Category added.`,
    })
  }

  return (
    <form onSubmit={createNewCategory} className={formClassName}>
      <input
        placeholder="New category"
        value={name}
        onChange={(event) => setName(event.target.value)}
        className={cx('h-10 px-2', className)}
      />
    </form>
  )
}

export default NewCategory
