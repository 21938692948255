import { ref } from 'firebase/storage'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useStorage } from 'reactfire'
import { getDownloadURL } from 'firebase/storage'
import {
  nextQuestionLoaded,
  selectIsQuestionReady,
  selectNextQuestion,
} from '../../../../game/logic/ducks/lifecycleDuck'
import { selectMessageProfile } from '../../../../user/logic/ducks/profileDuck'

interface Props {}

function NextQuestionLoader({}: Props): JSX.Element {
  const dispatch = useDispatch()
  const nextQuestion = useSelector(selectNextQuestion)
  const isAlreadyLoaded = useSelector(selectIsQuestionReady)
  const profile = useSelector(selectMessageProfile)
  const storage = useStorage()

  useEffect(() => {
    const { fullPath } = nextQuestion?.metadata || {}

    if (!fullPath || !profile || isAlreadyLoaded) return

    const preloadAudioFile = async () => {
      const fileReference = ref(storage, fullPath)
      const url = await getDownloadURL(fileReference)
      const audio = new Audio(url)

      // Todo - check if this works
      // audio.addEventListener('canplay');
      // await loadingDelay(1000);
      audio.addEventListener('canplaythrough', () => {
        dispatch(nextQuestionLoaded({ profile, nextQuestion: { url } }))
      })
    }

    // noinspection JSIgnoredPromiseFromCall
    preloadAudioFile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAlreadyLoaded, dispatch, nextQuestion?.metadata?.fullPath, storage])

  return null
}

export default NextQuestionLoader
