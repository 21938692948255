import { createSelector, createSlice, Draft, PayloadAction } from '@reduxjs/toolkit'

export interface ProviderData {
  displayName: string
  email: string
  phoneNumber: number
  photoURL: string
  providerId: string
  uid: string
}

export interface Profile {
  accessToken: string
  displayName: string
  email: string
  emailVerified: boolean
  isAnonymous: boolean
  photoURL: string
  providerData: ProviderData
  providerId: string
  tenantId: string
  uid: string
}

interface State {
  user: { profile: Profile }
}

const initialState: Profile = null

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    profileUpdated: (state: Draft<Profile>, action: PayloadAction<Partial<Profile>>) => {
      return { ...state, ...action.payload }
    },
    authStatusChanged: (state: Draft<Profile>, action: PayloadAction<any>) => {
      if (action.payload === null) return action.payload

      const {
        accessToken,
        displayName,
        email,
        emailVerified,
        isAnonymous,
        photoURL,
        providerData,
        providerId,
        tenantId,
        uid,
      } = action.payload

      return {
        accessToken,
        displayName,
        email,
        emailVerified,
        isAnonymous,
        photoURL,
        providerData,
        providerId,
        tenantId,
        uid,
      }
    },
  },
})

// Actions

export const { authStatusChanged, profileUpdated } = profileSlice.actions

// Reducer

export default profileSlice.reducer

// Selectors

export const selectUser = (state: State) => state.user.profile || initialState
export const selectUserDisplayName = (state: State) => selectUser(state)?.displayName
export const selectUserUid = (state: State) => selectUser(state)?.uid
export const selectPhotoURL = (state: State) => selectUser(state)?.photoURL

// Used for sending messages to the edge
export const selectMessageProfile = createSelector(
  selectUserUid,
  selectUserDisplayName,
  (uid, displayName) => ({ uid, displayName }),
)
