import { all, put, takeLatest } from 'redux-saga/effects'
import { roomEntered, roomRequested } from '../ducks/gameDuck'

export function* roomRequestedHandler({ payload }) {
  const { navigate } = payload

  // Todo - Communicate with edge to see if some random room code is available.
  const roomCode = yield 'mevink'

  yield navigate(`/room/${roomCode}`)
  yield put(roomEntered({ roomCode }))
}

export default function* joinRoomSaga() {
  yield all([takeLatest(roomRequested, roomRequestedHandler)])
}
