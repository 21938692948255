import cx from 'classnames'

interface HighlightProps {
  text: string
  className?: string
}

export const Highlight = ({ text, className }: HighlightProps) => (
  <strong className={cx('text-green-600', className)}>{text}</strong>
)
