import styles from './Layout.module.scss'
import { ReactNode } from 'react'
import cx from 'classnames'

interface Props {
  children?: ReactNode
  className?: string
}

function Layout({ children, className, ...props }: Props): JSX.Element {
  return (
    <div {...props} className={cx(styles.mobileLayout, className)}>
      {children}
    </div>
  )
}

export default Layout
