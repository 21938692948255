import { Link, useParams } from 'react-router-dom'
import { useMemo } from 'react'
import cx from 'classnames'
import { Question } from '../../../../model/Types'

function QuestionUpload(props: Partial<Question>): JSX.Element {
  const { name, slug } = props
  const { question: currentSlug } = useParams()
  const isActive = useMemo(() => slug === currentSlug, [slug, currentSlug])
  const to = isActive ? '' : `${slug}`
  return (
    <div className="flex flex-col w-auto">
      <Link
        to={to}
        className={cx('border-[1px] border-transparent pl-[3px] -ml-[3px]', {
          '!border-white !border-opacity-20 rounded ': isActive,
        })}
      >
        {name}
      </Link>

      {/* debug info */}
      {isActive ? (
        <div className="overflow-auto">
          <pre className="text-xs break-all whitespace-pre-wrap text-gray-500">
            {JSON.stringify(props, null, 2)
              .replaceAll('\n  },', '')
              .replace(/^{\n/, '')
              .replaceAll('{', '')
              .replaceAll('}', '')}
          </pre>
        </div>
      ) : null}
    </div>
  )
}

export default QuestionUpload
