import { useDispatch, useSelector } from 'react-redux'
import { numberOfRoundsSet, selectNumberOfRounds } from '../../../logic/ducks/gameDuck'
import { useCallback, useMemo } from 'react'
import cx from 'classnames'

interface Props {
  className?: string
  optionClassName?: string
}

function RoundsPicker({ className, optionClassName }: Props): JSX.Element {
  const rounds = useSelector(selectNumberOfRounds)
  const dispatch = useDispatch()
  const options = useMemo(() => Array.from({ length: 5 }).map((_, index) => index + 1), [])
  const onChangeRounds = useCallback(
    (event) => dispatch(numberOfRoundsSet(Number(event.target.value))),
    [dispatch],
  )

  return (
    <select
      className={cx(
        'text-green-600 font-bold bg-transparent border-[1px] rounded-md px-2 mx-1 text-left border-opacity-50 hover:border-opacity-100',
        className,
      )}
      value={rounds}
      onChange={onChangeRounds}
    >
      {options.map((option) => (
        <option key={option} value={option} className={cx('text-gray-800 p-6', optionClassName)}>
          {option}
        </option>
      ))}
    </select>
  )
}

export default RoundsPicker
