import { useParams } from 'react-router-dom'
import Category from './Category'
import {
  DottedLineItem,
  DottedLineGroup,
} from '../../../../shared/ui/DirectoryStructure/DottedLineGroup'
import NewCategory from './NewCategory'
import Inventory from '../../../model/Inventory'
import { collection, orderBy, query } from 'firebase/firestore'
import { useFirestore, useFirestoreCollectionData, useUser } from 'reactfire'
import { Suspense } from 'react'

interface Props {}

function Categories({}: Props): JSX.Element {
  const { quizType } = useParams()
  const { data: user } = useUser()
  const firestore = useFirestore()
  const categoriesCollection = collection(firestore, Inventory.getCategoriesPath(user.uid))
  const categoriesQuery = query(categoriesCollection, orderBy('id', 'asc'))
  const { data: categories } = useFirestoreCollectionData(categoriesQuery, {
    // @ts-ignore
    idField: `id-${user.stsTokenManager.expirationTime}`,
  })

  return categories ? (
    <DottedLineGroup className="p-6 pt-3 pr-0 bg-black bg-opacity-20 rounded-b-2xl">
      <DottedLineItem center className="py-2" lineClassName="!mt-0 mr-0">
        <NewCategory quizType={quizType} className="rounded bg-[#354660]" />
      </DottedLineItem>
      <Suspense fallback="loading categories...">
        {categories
          .filter((category) => category.quizType === quizType)
          .map((props) => (
            <Category key={props.slug} {...props} />
          ))}
      </Suspense>
    </DottedLineGroup>
  ) : null
}

export default Categories
