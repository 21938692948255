import cx from 'classnames'
import styles from '../Organiser.module.scss'
import { NavLink } from 'react-router-dom'

const SidebarLink = ({ path, exact = false, title }) => (
  <NavLink
    to={path}
    end={exact}
    className={({ isActive }) => cx(styles.navLink, { [styles.active]: isActive })}
  >
    {title}
  </NavLink>
)

export default SidebarLink
