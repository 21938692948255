import cx from 'classnames'
import React, { ReactNode } from 'react'

interface DottedLinesProps {
  children: ReactNode
  className?: string
  lineClassName?: string
}

export const DottedLineGroup = ({ children, className, lineClassName }: DottedLinesProps) => {
  return children ? (
    <div className={cx('flex flex-row', className)}>
      <div className={cx('mt-0.5 w-0 border-r-2 border-dotted mb-3', lineClassName)} />
      <div className="flex flex-col pt-0.5 w-full">{children}</div>
    </div>
  ) : null
}

interface DottedLineItemProps {
  children: ReactNode
  center?: boolean
  className?: string
  lineClassName?: string
}

export const DottedLineItem = ({
  children,
  center,
  className,
  lineClassName,
}: DottedLineItemProps) => (
  <div className={cx('flex flex-row', { 'items-center': center }, className)}>
    <div
      className={cx(
        'h-1 w-3 flex-shrink-0 mr-2 mt-3 border-t-2 border-dotted',
        { '!mt-0': center },
        lineClassName,
      )}
    />
    <div className="w-full flex-shrink">{children}</div>
  </div>
)
