import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import { all } from 'redux-saga/effects'
import { SocketService } from './core/service/socketService'
import { shared, sharedSaga } from './shared/logic'
import { room, roomSaga } from './room/logic'
import { game, gameSaga } from './game/logic'
import { user, userSaga } from './user/logic'
import { organiser, organiserSaga } from './organiser/logic'

const rootReducer = { shared, user, organiser, room, game }

function* rootSaga() {
  yield all([sharedSaga(), userSaga(), organiserSaga(), roomSaga(), gameSaga()])
}

const partyService = new SocketService()

export function setupStore() {
  const sagaMiddleware = createSagaMiddleware({ context: { partyService } })

  // Configure store with reducers and middleware
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
  })

  sagaMiddleware.run(rootSaga)

  return store
}
