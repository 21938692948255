import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Question } from '../../../organiser/model/Types'
import { Game, GameSummary } from '../../../organiser/logic/ducks/gameDuck'
import { Profile } from '../../../user/logic/ducks/profileDuck'

interface GameLifecycle {
  gameLoaded: boolean

  summary: GameSummary | null

  currentCategory: any
  isLastCategory: boolean

  currentQuestion: any
  isQuestionReady: boolean
  isQuestionRevealed: boolean
  isQuestionPaused: boolean
  isLastQuestion: boolean

  nextQuestion: any

  answerQueue: Array<any>

  scores: {
    players: Array<Partial<Profile>>
  }
  isScoreDisplayed: boolean
}

interface State {
  game: { lifecycle: GameLifecycle }
}

const initialState: GameLifecycle = {
  gameLoaded: false,

  summary: null,

  currentCategory: null,
  isLastCategory: null,

  currentQuestion: null,
  isQuestionReady: false,
  isQuestionRevealed: false,
  isQuestionPaused: false,
  isLastQuestion: null,

  nextQuestion: null,

  answerQueue: [],

  scores: null,
  isScoreDisplayed: false,
}

export const lifecycleSlice = createSlice({
  name: 'lifecycle',
  initialState,
  reducers: {
    /**
     * Game
     */

    requestResetGame: (state, action: Action) => {},
    gameReset: (state, action: Action) => {
      return initialState
    },

    requestLoadGame: (state, action: PayloadAction<Game>) => {},
    gameLoaded: (state, action: PayloadAction<GameSummary>) => {
      state.gameLoaded = true
      state.summary = action.payload
      state.isScoreDisplayed = false
    },

    /**
     * Category
     */

    requestNextCategory: (state, action: Action) => {},
    categoryLoaded: (state, action: PayloadAction<{ category: any; isLastCategory: boolean }>) => {
      const { category, isLastCategory } = action.payload

      state.answerQueue = []
      state.currentCategory = category
      state.isLastCategory = isLastCategory
      state.isQuestionReady = false
      state.isScoreDisplayed = false
    },

    /**
     * Question (loading)
     */

    requestLoadNextQuestion: (state, action: PayloadAction<Partial<Question>>) => {},
    loadNextQuestion: (state, action: PayloadAction<Partial<Question>>) => {
      state.nextQuestion = action.payload
      state.isQuestionReady = false
    },
    nextQuestionLoaded: (
      state,
      action: PayloadAction<{ profile: Partial<Profile>; nextQuestion: Partial<Question> }>,
    ) => {
      // Adds the url
      state.nextQuestion = { ...state.nextQuestion, ...action.payload.nextQuestion }
    },
    nextQuestionLoadedByEveryone: (state) => {
      state.isQuestionReady = true
    },

    /**
     * Question (display)
     */

    requestStartNextQuestion: (state, action: Action) => {},
    nextQuestionStarted: (state, action: Action) => {
      state.currentQuestion = state.nextQuestion
      state.isQuestionRevealed = false
      state.isQuestionPaused = false
      state.nextQuestion = null

      state.answerQueue = []
      state.isScoreDisplayed = false
    },
    questionRevealed: (state, action: Action) => {
      state.isQuestionRevealed = true
    },

    /**
     * Question (pause and resume)
     */

    requestPauseQuestion: (state, action: Action) => {},
    questionPaused: (state, action: Action) => {
      state.isQuestionPaused = true
    },

    requestResumeQuestion: (state, action: Action) => {},
    questionResumed: (state, action: Action) => {
      state.isQuestionPaused = false
    },

    /**
     * ButtonPress
     */
    requestButtonPress: (state, action: PayloadAction<Partial<Profile>>) => {},
    requestAnswerQueueReset: (state, action: Action) => {},
    answerQueueUpdated: (state, action: PayloadAction<{ updatedQueue: Array<any> }>) => {
      const { updatedQueue } = action.payload

      state.answerQueue = updatedQueue
    },

    /**
     * Answers
     */

    requestAnsweredCorrectly: (
      state,
      action: PayloadAction<{ player: Partial<Profile>; points: number }>,
    ) => {},
    answeredCorrectly: (state, action: PayloadAction<{ updatedQueue: Array<any> }>) => {
      const { updatedQueue } = action.payload

      state.answerQueue = updatedQueue
    },

    requestAnsweredIncorrectly: (
      state,
      action: PayloadAction<{ player: Partial<Profile>; points: number }>,
    ) => {},
    answeredIncorrectly: (state, action: PayloadAction<{ updatedQueue: Array<any> }>) => {
      const { updatedQueue } = action.payload

      state.answerQueue = updatedQueue
    },

    /**
     * Scores
     */
    requestScores: (state, action: Action) => {},
    showScores: (state, action) => {
      state.scores = action.payload
      state.isScoreDisplayed = true
    },
  },
})

// Actions

export const {
  requestLoadGame,
  gameLoaded,
  requestResetGame,
  gameReset,
  requestNextCategory,
  categoryLoaded,
  requestLoadNextQuestion,
  loadNextQuestion,
  nextQuestionLoaded,
  nextQuestionLoadedByEveryone,
  requestStartNextQuestion,
  nextQuestionStarted,
  questionRevealed,
  requestPauseQuestion,
  questionPaused,
  requestResumeQuestion,
  questionResumed,
  requestButtonPress,
  requestAnswerQueueReset,
  answerQueueUpdated,
  requestAnsweredCorrectly,
  answeredCorrectly,
  requestAnsweredIncorrectly,
  answeredIncorrectly,
  requestScores,
} = lifecycleSlice.actions

// Reducer

export default lifecycleSlice.reducer

// Selectors

export const selectGame = (state: State) => state.game.lifecycle || initialState
export const selectSummary = (state: State) => selectGame(state).summary
export const selectHasGameLoaded = (state: State) => selectGame(state).gameLoaded
export const selectCurrentCategory = (state: State) => selectGame(state).currentCategory
export const selectIsLastCategory = (state: State) => selectGame(state).isLastCategory
export const selectCurrentQuestion = (state: State) => selectGame(state).currentQuestion
export const selectIsQuestionReady = (state: State) => selectGame(state).isQuestionReady
export const selectIsQuestionRevealed = (state: State) => selectGame(state).isQuestionRevealed
export const selectIsQuestionPaused = (state: State) => selectGame(state).isQuestionPaused
export const selectIsLastQuestion = (state: State) => selectGame(state).isLastQuestion
export const selectNextQuestion = (state: State) => selectGame(state).nextQuestion
export const selectScores = (state: State) => selectGame(state).scores
export const selectIsScoreDisplayed = (state: State) => selectGame(state).isScoreDisplayed
export const selectAnswerQueue = (state: State) => selectGame(state).answerQueue
