import { useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './Loading.module.scss'

const animationSpeed = 750

interface Props {
  name: string
  className?: string
}

export const Loading = ({ name, className = '' }: Props) => {
  const [length, setLength] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      return length === 3 ? setLength(0) : setLength((l) => l + 1)
    }, animationSpeed)

    return () => clearInterval(interval)
  })

  const dotsString = Array.from({ length }).fill('.').join('')
  const textStyle = {
    filter: `brightness(${40 + length * 15}%)`,
    transition: `filter ${animationSpeed}ms ease-out`,
  }

  return (
    <div className={cx(className, styles.main)}>
      <p className={styles.text} style={textStyle}>
        <span className={styles.loading}>{`Loading ${name}`.trim()}</span>
        <span className={styles.dots}>{dotsString}</span>
      </p>
    </div>
  )
}
