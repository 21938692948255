import { useSelector } from 'react-redux'
import { selectIsScoreDisplayed, selectScores } from '../../../game/logic/ducks/lifecycleDuck'

interface Props {}

function Scores({}: Props): JSX.Element {
  const scores = useSelector(selectScores)

  return scores ? (
    <div className="absolute w-full h-full bg-gray-900 flex flex-col items-center justify-center gap-3">
      <h2 className="text-2xl">Scores</h2>
      <div className="columns-2 gap-x-5">
        {scores.players.map((player) => {
          return (
            <li key={player.uid} className="text-md">
              {/* @ts-ignore */}
              {player.displayName}: {player.score}
            </li>
          )
        })}
      </div>
    </div>
  ) : null
}

export default Scores
