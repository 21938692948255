import { useSelector } from 'react-redux'
import { selectNumberOfRounds } from '../../../logic/ducks/gameDuck'
import RoundsPicker from './RoundsPicker'
import { Highlight } from './Highlight'
import config from '../../../../shared/config'
import { pluralise } from '../../../../core/utils/pluralise'
import { useMemo } from 'react'

interface Props {}

function ExplanationHeader({}: Props): JSX.Element {
  const rounds = useSelector(selectNumberOfRounds)

  const duration = useMemo(() => {
    if (rounds === 1) return 'a quarter' // people need to join
    if (rounds === 2) return '25 minutes' // second round goes fast
    if (rounds === 3) return '40 minutes' // a small break is needed
    if (rounds === 4) return 'an hour' // a bigger break is needed
    if (rounds === 5) return '1 ½ hours' // a bigger break + people start talking more
    return `${rounds * 15} minutes`
  }, [rounds])

  return (
    <div className="text-sm sm:text-xl md:text-2xl lg:text-3xl space-y-6 pb-[1.25em]">
      <p>
        <span>Each round consists of </span>
        <Highlight
          className="text-xs sm:text-xl md:text-2xl lg:text-3xl"
          text={config.game.questionsPerRound.toString()}
        />
        <span> questions.</span>
      </p>
      <p>
        <span>A game with </span>
        <RoundsPicker className="text-xs sm:text-xl md:text-2xl lg:text-3xl" />
        <span> {pluralise(rounds, 'round', 'rounds')} lasts about </span>
        <Highlight className="text-xs sm:text-xl md:text-2xl lg:text-3xl" text={duration} />
        <span>.</span>
      </p>
    </div>
  )
}

export default ExplanationHeader
