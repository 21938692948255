import React from 'react'
import { IconContext } from 'react-icons'
import loadable, { LoadableComponent } from '@loadable/component'

interface Props extends IconContext {
  icon: string
  className?: string
}

const DynamicIcon: React.FC<Props> = ({ icon, className, ...iconContext }) => {
  let library = icon?.match(/[A-Z][a-z]+/g)[0].toLowerCase()
  if (library === 'fa') library = 'fa6'
  const fallback = <div>•</div>

  if (!library || !icon) return fallback

  const Icon: LoadableComponent<React.SVGAttributes<SVGElement>> = loadable(
    () => import(/* webpackPrefetch: true */ `react-icons/${library}/index.js`),
    {
      resolveComponent: (element: JSX.Element) => element[icon as keyof JSX.Element],
    },
  )

  return (
    <IconContext.Provider value={iconContext}>
      <Icon className={className} />
    </IconContext.Provider>
  )
}

export default DynamicIcon
