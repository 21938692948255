export const DateFormat = {
  simpleTimeIn24HourFormat(dateOrTimestamp: Date | number): string {
    const date = typeof dateOrTimestamp === 'number' ? new Date(dateOrTimestamp) : dateOrTimestamp

    const hours = `0${date.getHours()}`.slice(-2)
    const minutes = `0${date.getMinutes()}`.slice(-2)

    return `${hours}:${minutes}`
  },
}
