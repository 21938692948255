import cx from 'classnames'
import styles from './ConnectionStatus.module.scss'
import { useSelector } from 'react-redux'
import { selectIsConnected } from '../../../logic/ducks/connectivityDuck'

const ConnectionStatus = ({ className = '' }) => {
  const isConnected = useSelector(selectIsConnected)
  return <div className={cx(styles.status, { [styles.connected]: isConnected }, className)}>⬤</div>
}

export default ConnectionStatus
