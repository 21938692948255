import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Profile } from '../../../user/logic/ducks/profileDuck'
import { socketConnected } from './connectivityDuck'

interface PlayersState {
  players: Partial<Profile>[]
}

interface State {
  room: { players: PlayersState }
}

const initialState: PlayersState = {
  players: [],
}

export const playersSlice = createSlice({
  name: 'players',
  initialState,
  reducers: {
    playerJoined: (state, action: PayloadAction<Partial<Profile>>) => {
      if (!state.players.some((player) => player.uid === action.payload.uid)) {
        state.players.push(action.payload)
      }
    },
    playerQuit: (state, action: PayloadAction<Partial<Profile>>) => {
      state.players = state.players.filter((member) => member.uid !== action.payload.uid)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(socketConnected, () => {
      // Todo - load game state
      return initialState
    })
  },
})

// Actions

export const { playerJoined, playerQuit } = playersSlice.actions

// Reducer

export default playersSlice.reducer

// Selectors

export const selectPlayersRoot = (state: State) => state.room.players || initialState
export const selectPlayers = (state: State) => selectPlayersRoot(state).players
