import styles from '../Organiser.module.scss'
import SidebarLink from './SidebarLink'
import Logo from '../../../shared/ui/Logo/Logo'
import { ProfileCard } from './ProfileCard'

const Sidebar = () => {
  return (
    <ul className={styles.sidebar}>
      <SidebarLink path="../" exact title={<Logo className="text-base" />} />
      <SidebarLink path="inventory" title="Inventory" />
      <SidebarLink path="start-game" title="Start game" />
      {/*<SidebarLink path="inventory" title="Inventory" />*/}
      <div className={styles.fill} />
      <ProfileCard />
    </ul>
  )
}

export default Sidebar
