import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Profile } from '../../../user/logic/ducks/profileDuck'

import {
  answerQueueUpdated,
  categoryLoaded,
  gameReset,
  nextQuestionLoaded,
  nextQuestionStarted,
  requestButtonPress,
  requestLoadGame,
  requestNextCategory,
} from './lifecycleDuck'

type MashersQueue = Array<{ player: Partial<Profile>; hasAnswered: boolean }>

interface ButtonMashingState {
  hasAnyoneMashedTheButton: boolean
  hasOwnButtonBeenMashed: boolean
  mashersQueue: MashersQueue
}

interface State {
  game: { button: ButtonMashingState }
}

const initialState: ButtonMashingState = {
  hasAnyoneMashedTheButton: false,
  hasOwnButtonBeenMashed: false,
  mashersQueue: [],
}

export const buttonSlice = createSlice({
  name: 'button',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(requestButtonPress, (state) => {
      state.hasOwnButtonBeenMashed = true
    })
    builder.addCase(requestLoadGame, (state, action: Action) => {
      return initialState
    })
    builder.addCase(gameReset, () => {
      return initialState
    })
    builder.addCase(
      answerQueueUpdated,
      (state, action: PayloadAction<{ updatedQueue: Array<any> }>) => {
        const { updatedQueue } = action.payload
        if (updatedQueue.length === 0) {
          state.hasOwnButtonBeenMashed = false
        }
      },
    )
    builder.addCase(categoryLoaded, (state, action: Action) => {
      state.mashersQueue = []
      state.hasAnyoneMashedTheButton = false
      state.hasOwnButtonBeenMashed = false
    })
    builder.addCase(nextQuestionStarted, (state, action: Action) => {
      state.mashersQueue = []
      state.hasAnyoneMashedTheButton = false
      state.hasOwnButtonBeenMashed = false
    })
  },
})

// Actions

// Reducer

export default buttonSlice.reducer

// Selectors

export const selectButtonRoot = (state: State) => state.game.button || initialState
export const selectHasOwnButtonBeenMashed = (state: State) =>
  selectButtonRoot(state).hasOwnButtonBeenMashed
export const selectHasAnyoneMashedTheButton = (state: State) =>
  selectButtonRoot(state).hasAnyoneMashedTheButton
export const selectButtonMashersQueue = (state: State) => selectButtonRoot(state).mashersQueue
