import styles from './Organiser.module.scss'
import { Routes, Route, Navigate } from 'react-router-dom'
import Sidebar from './Sidebar/Sidebar'
import { Loading } from '../../shared/ui/Loading/Loading'
import { SuspenseWithPerf } from 'reactfire'
import SignInForm from '../../shared/ui/SignInForm/SignInForm'
import InventoryPage from './Legacy/Inventory/InventoryPage'
import Page from 'shared/ui/Page/Page'
import UserContentWrapper from '../../core/ui/reactfire/Auth/UserContentWrapper/UserContentWrapper'
import Inventory from './Inventory/Inventory'
import Categories from './Inventory/Category/Categories'
import Layout from '../../shared/ui/Layout/Layout'
import StartGame from './StartGame/StartGame'
import ErrorBoundary from '../../core/ui/react/ErrorBoundary'
import SelectCategoryModal from './StartGame/SelectedCategories/SelectCategoryModal'

const Organiser = () => {
  return (
    <UserContentWrapper fallback={<SignInForm />}>
      <Page>
        <ErrorBoundary>
          <SelectCategoryModal index={1} />
          <SelectCategoryModal index={2} />
          <SelectCategoryModal index={3} />
          <SelectCategoryModal index={4} />
          <SelectCategoryModal index={5} />
        </ErrorBoundary>

        <div className={styles.organiser}>
          <Sidebar />

          <Layout className="overflow-y-auto">
            <div className={styles.main}>
              <SuspenseWithPerf
                fallback={<Loading name="organiser" />}
                traceId={'load-organiser-route'}
              >
                <Routes>
                  <Route index element={<Navigate to="inventory" />} />
                  <Route path="inventory">
                    <Route index element={<Inventory />} />
                    <Route path=":quizType" element={<Inventory />}>
                      <Route index element={<Categories />} />
                      <Route path=":category" element={<Categories />}>
                        <Route path=":question" element={<p>question placeholder</p>} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="start-game/*" element={<StartGame />} />
                  <Route path="test/*" element={<InventoryPage />} />
                </Routes>
              </SuspenseWithPerf>
            </div>
          </Layout>
        </div>
      </Page>
    </UserContentWrapper>
  )
}

export default Organiser
