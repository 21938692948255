const Emojis = ({ className, typeFn }) => {
  const emojis = ['🙂', '😆', '🎉', '🚀', '❤', '💡', '👏', '💯', '👍', '👎']

  return (
    <ul className={className}>
      <h3>&nbsp;</h3>
      {emojis.map((emoji) => (
        <li key={emoji} onClick={() => typeFn(emoji)} style={{ cursor: 'pointer' }}>
          {emoji}
        </li>
      ))}
    </ul>
  )
}

export default Emojis
