import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { CategoryProps } from '../../../model/Types'
import { useCallback } from 'react'
import { categorySelected } from '../../../logic/ducks/gameDuck'

interface Props extends Partial<CategoryProps> {
  index: number
}

function SelectedCategory({ index, title, quizTypeTitle }: Props): JSX.Element {
  const dispatch = useDispatch()

  const removeCategory = useCallback(
    (event) => {
      event.preventDefault()
      dispatch(categorySelected({ index, category: null }))
    },
    [dispatch, index],
  )

  return (
    <div className="p-10 border-dashed border-b-4 last:border-b-0 bg-black bg-opacity-20">
      {quizTypeTitle ? (
        <Link
          to={`#select-category-${index}`}
          className="group p-2 border-2 text-blue-200 bg-black bg-opacity-50 w-full flex"
        >
          <div>
            {title} {quizTypeTitle.toLowerCase()}
          </div>
          <button className="ml-auto text-xs" onClick={removeCategory}>
            ❌
          </button>
        </Link>
      ) : (
        <Link
          to={`#select-category-${index}`}
          className="p-2 border-2 border-transparent w-full flex"
        >
          <p>
            <u>Select</u>
            <span> a category.</span>
          </p>
        </Link>
      )}
    </div>
  )
}

export default SelectedCategory
