import React from 'react'
import { useSelector } from 'react-redux'
import { selectSummary } from '../../../../game/logic/ducks/lifecycleDuck'

interface Props {}

const GameLoaded = ({}: Props): JSX.Element => {
  const summary = useSelector(selectSummary)

  return (
    <div className="text-center">
      <p>Game loaded.</p>
      {JSON.stringify(summary, null, 2)}
      <p>Host will start the game soon.</p>
    </div>
  )
}

export default GameLoaded
