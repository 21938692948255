export default class Inventory {
  public static getCategoriesPath(userUid) {
    return `users/${userUid}/categories`
  }

  public static getQuestionsPath(userUid, categorySlug) {
    return `users/${userUid}/categories/${categorySlug}/questions`
  }

  public static getQuestionFilesPath(userUid, categorySlug) {
    return `users/${userUid}/categories/${categorySlug}/questions`
  }
}
