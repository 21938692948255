import { useCallback } from 'react'
import { useFirestore, useUser } from 'reactfire'
import { useNotification } from '../../../../core/hooks/useNotification'
import { doc, deleteDoc } from 'firebase/firestore'
import { CategoryProps } from '../../../model/Types'
import Inventory from '../../../model/Inventory'
import cx from 'classnames'

interface Props extends Partial<CategoryProps> {
  className?: string
}

function DeleteCategory({ className, slug }: Props): JSX.Element {
  const firestore = useFirestore()
  const notify = useNotification()
  const { data: user } = useUser()
  const categoriesPath = Inventory.getCategoriesPath(user.uid)

  const deleteCategory = useCallback(
    async (event) => {
      event.preventDefault()

      const deleteAction = async () => {
        const documentReference = doc(firestore, categoriesPath, slug)
        await deleteDoc(documentReference)

        // Todo delete sub-collection, and related files
        // See https://stackoverflow.com/questions/47860812/deleting-all-documents-in-firestore-collection
      }

      if (
        window.confirm('\nThis will delete the category.\nAll files will be lost.\nAre you sure?')
      ) {
        await notify.promise(deleteAction(), {
          loading: 'Deleting category.',
          error: (error) => `An error occurred. ${error.message}`,
          success: `Category deleted.`,
        })
      }
    },
    [categoriesPath, firestore, notify, slug],
  )

  return (
    <button onClick={deleteCategory} className={cx('px-2 text-xs', className)}>
      ❌
    </button>
  )
}

export default DeleteCategory
