import {
  takeLatest,
  put,
  call,
  take,
  fork,
  getContext,
  all,
  cancel,
  delay,
  select,
  race,
} from 'redux-saga/effects'
import { eventChannel } from 'redux-saga'
import {
  socketConnected,
  requestToJoinByCode,
  socketDisconnected,
  ready,
  joined,
  failedToJoin,
  requestReconnect,
} from '../ducks/connectivityDuck'
import config from '../../../shared/config'
import { EventBuffer } from '../../../core/model/EventBuffer'
import { SocketEvent, SocketService } from '../../../core/service/socketService'
import { readMessage } from '../ducks/chatDuck'
import { isDevelopment } from '../../../core/utils/isProduction'
import {
  selectPhotoURL,
  selectUserDisplayName,
  selectUserUid,
} from '../../../user/logic/ducks/profileDuck'
import { playerJoined } from '../ducks/playersDuck'
import { loadingDelay } from '../../../core/utils/loadingDelay'

function createSocketChannel(socket) {
  const buffer = new EventBuffer<SocketEvent>()

  return eventChannel((emit) => {
    const open = () => emit(socketConnected())
    const close = () => emit(socketDisconnected())
    const error = (errorEvent) => emit(new Error(errorEvent.reason))
    const message = (event) => {
      const data = JSON.parse(event.data)

      const { type, payload } = data
      if (type && payload) {
        if (isDevelopment()) console.log('receiving', type, payload)

        emit({ type, payload })
      } else {
        emit(readMessage(data))
      }
    }

    socket.addEventListener('open', open)
    socket.addEventListener('close', close)
    socket.addEventListener('error', error)
    socket.addEventListener('message', message)

    return () => {
      socket.close()
      socket.removeEventListener('open', open)
      socket.removeEventListener('close', close)
      socket.removeEventListener('error', error)
      socket.removeEventListener('message', message)
    }
  }, buffer)
}

function* watchPartyEvents() {
  const { socket } = yield getContext('partyService')
  const socketChannel = yield call(createSocketChannel, socket)
  while (true) {
    try {
      const event = yield take(socketChannel)
      yield put(event)
    } catch (error) {
      console.error('socket error:', error)
      socketChannel.close()
    }
  }
}

function* tryToJoinByCode({ payload }) {
  const roomCode = payload.toLowerCase()

  const party: SocketService = yield getContext('partyService')
  party.setUrl(`wss://${config.apiUrl}/api/room/${roomCode}/websocket`)
  party.connect()
  party.watcher = yield fork(watchPartyEvents)
}

function* tryToReconnect() {
  const party: SocketService = yield getContext('partyService')
  try {
    yield cancel(party.watcher)
    party.reconnectAttempts += 1

    console.log(`reconnecting attempt ${party.reconnectAttempts}...`)
    party.reconnect()
    // Todo - Reconnecting requires identifying as a user, implement it

    party.watcher = yield fork(watchPartyEvents)
    party.reconnectAttempts = 0
  } catch (error) {
    console.error('Unable to reconnect:', error)

    if (party.reconnectAttempts > SocketService.maxReconnectAttempts) {
      console.error('Maximum number of reconnect attempts exceeded.')
      return
    }

    const delayMs = party.reconnectAttempts * 1000
    console.log(`Reconnecting in ${delayMs}`)
    yield delay(delayMs)
    yield call(tryToReconnect())
  }
}

function* registerAsPlayer() {
  try {
    const party: SocketService = yield getContext('partyService')
    const displayName = yield select(selectUserDisplayName)
    const uid = yield select(selectUserUid)
    const photoURL = yield select(selectPhotoURL)

    party.send(playerJoined({ displayName, uid, photoURL }) as SocketEvent)
    const { success, failure } = yield race({
      success: take(ready),
      failure: loadingDelay(15_000),
    })

    if (failure) throw new Error('Maximum time to try and connect expired.')
    if (success) yield put(joined())
  } catch (error) {
    console.error(error)
    yield put(failedToJoin('An unexpected error occurred, please try again later.'))
  }
}

export function* connectionSaga() {
  yield all([
    takeLatest(requestToJoinByCode, tryToJoinByCode),
    takeLatest(socketConnected, registerAsPlayer),
    takeLatest(socketDisconnected, tryToReconnect),
    takeLatest(requestReconnect, tryToReconnect),
  ])
}
