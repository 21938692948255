import { useSelector } from 'react-redux'
import { selectCurrentQuestion } from '../../../game/logic/ducks/lifecycleDuck'

interface Props {}

function QuestionInfo({}: Props): JSX.Element {
  const question = useSelector(selectCurrentQuestion)

  return question ? (
    <div className="absolute top-2 left-2">
      <h2>{question.path}</h2>
      <pre className="text-xs break-all whitespace-pre-wrap text-gray-500">
        {JSON.stringify(question, null, 2)
          .replaceAll('\n  },', '')
          .replace(/^{\n/, '')
          .replaceAll('{', '')
          .replaceAll('}', '')}
      </pre>
    </div>
  ) : null
}

export default QuestionInfo
