import styles from './Social.module.scss'
import ChatLine from './ChatLine'
import { useSelector } from 'react-redux'
import { selectChatMessages } from '../../../logic/ducks/chatDuck'
import { useEffect, useRef } from 'react'

const ChatLog = () => {
  const log = useRef<HTMLUListElement>()
  const messages = useSelector(selectChatMessages)

  useEffect(() => {
    if (!log.current) return
    log.current.scrollTo({ top: 9e9, behavior: 'smooth' })
  }, [log, messages])

  return (
    <div className={styles.chatLogWrapper}>
      <ul ref={log} className={styles.chatLog}>
        {messages.map((message) => (
          <ChatLine key={message.timestamp} {...message} />
        ))}
      </ul>
      <div className={styles.fader} />
    </div>
  )
}

export default ChatLog
