import { ChatMessage } from '../../../logic/ducks/chatDuck'
import { DateFormat } from '../../../../core/model/DateFormat'
import styles from './Social.module.scss'

interface ChatLineProps extends Omit<ChatMessage, 'name'> {
  displayName?: string
}

const ChatLine = (line: ChatLineProps) => {
  const { timestamp, displayName, message } = line
  const formattedTimestamp = DateFormat.simpleTimeIn24HourFormat(timestamp)
  const Timestamp = () => <span className={styles.timestamp}>{formattedTimestamp}</span>
  const Name = () => (displayName ? <span className={styles.name}>{displayName}</span> : null)
  const Message = () => <span className={styles.message}>{message}</span>

  return (
    <li key={timestamp} className={styles.line}>
      <Timestamp />
      <Name />
      <Message />
    </li>
  )
}

export default ChatLine
