import { useSelector } from 'react-redux'
import { selectPlayers } from '../../../logic/ducks/playersDuck'

const Players = ({ className }) => {
  const players = useSelector(selectPlayers)

  return (
    <ul className={className}>
      <h3>Players</h3>
      {players.map((player) => {
        const { displayName, uid } = player
        return (
          <li key={uid} className="text-gray-500">
            {displayName}
          </li>
        )
      })}
    </ul>
  )
}

export default Players
