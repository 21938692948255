import { useSelector } from 'react-redux'
import { selectCurrentCategory } from '../../../game/logic/ducks/lifecycleDuck'

interface Props {}

function CategoryDisplay({}: Props): JSX.Element {
  const category = useSelector(selectCurrentCategory)

  if (!category) return null

  return (
    <div>
      <p className="text-xl sm:text-3xl md:text-4xl">
        {category.title}
        {/*{category.quizTypeTitle?.toLowerCase()}*/}
      </p>
    </div>
  )
}

export default CategoryDisplay
